import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content/content.service';
import { HtmlProcessingService } from '../services/html-processing/html-processing.service';
import { UtilityService } from '../services/utility/utility.service';
import { Block, CareModel } from '../models/content.model';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { forkJoin, Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { ToolTipMap } from '../models/tool-tip-map.model';

@Component({
  selector: 'app-care-model',
  templateUrl: './care-model.component.html',
  styleUrls: ['./care-model.component.scss'],
})
export class CareModelComponent implements OnInit, OnDestroy {
  careModel!: any;
  careModels!: CareModel[];
  codename!: string;
  getContentSubscription!: Subscription;
  isAco!: boolean;
  lang!: string;
  path!: string;
  processedTabs: any[] = [];
  selectedIndex = 0;
  state!: string;
  tabs!: any[];
  toolTipKeys!: string[];
  toolTipMap!: ToolTipMap;

  constructor(
    private contentService: ContentService,
    private htmlProcessingService: HtmlProcessingService,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.path = this.routeHelperService.getFinalPathValue();
    this.codename = this.routeHelperService.getCodeNameByUrl(
      this.lang,
      this.state,
      this.path
    );

    this.getContentSubscription = forkJoin({
      blocks: this.contentService.getBlocks(this.lang),
      careModels: this.contentService.getCareModels(this.lang, this.state),
      glossary: this.contentService.getGlossaryItems(this.lang, this.state),
    }).subscribe(
      ({ blocks, careModels, glossary }) => {
        try {
          this.careModels = cloneDeep(careModels.items);
          this.filterCareModel();
          this.toolTipMap = this.utilityService.createToolTipMap(
            glossary.items
          );
          this.toolTipKeys = Object.keys(this.toolTipMap);
          this.tabs = this.utilityService.createTabs(this.careModel);

          // Add tooltips.
          if (this.careModel?.introduction.value)
            this.careModel.introduction.value =
              this.htmlProcessingService.addToolTips(
                this.careModel.introduction.value,
                this.toolTipMap,
                this.toolTipKeys
              );

          if (this.careModel?.body.value)
            this.careModel.body.value = this.htmlProcessingService.addToolTips(
              this.careModel.body.value,
              this.toolTipMap,
              this.toolTipKeys
            );

          this.processTabContent(blocks.items);

          // Fix Kentico phone number links.
          if (this.careModel?.body.value.toLowerCase().includes('tel:'))
            this.careModel.body.value =
              this.htmlProcessingService.fixPhoneNumberLinks(
                this.careModel.body.value
              );
        } catch {
          console.error('There was a problem fetching page content.');
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  filterCareModel(): void {
    this.careModels.forEach((careModel: CareModel) => {
      if (careModel.system.codename === this.codename) {
        this.careModel = careModel;
        this.isAco =
          this.careModel.system.codename === 'oh_aco' ||
          this.careModel.system.codename === 'ca_aco';
      }
    });
  }

  // Get additional tab content from Kentico blocks.
  processTabContent(blocks: Block[]): void {
    this.tabs.forEach((tab) => {
      // Add the tab to the processedTabs array so it's order is preserved.
      // It's body content will be overwritten with the processed tab.
      this.processedTabs.push(tab);

      try {
        if (tab.body.value) {
          tab.body.value = this.htmlProcessingService.processKenticoHtml(
            tab.body.value,
            blocks
          );
        }

        // Fix Kentico phone number links.
        if (tab.body.value.toLowerCase().includes('tel:'))
          tab.body.value = this.htmlProcessingService.fixPhoneNumberLinks(
            tab.body.value
          );

        // Add tooltips.
        if (tab.body.value)
          tab.body.value = this.htmlProcessingService.addToolTips(
            tab.body.value,
            this.toolTipMap,
            this.toolTipKeys
          );
      } catch {
        console.error('There was a problem processing tab content.');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.getContentSubscription) this.getContentSubscription.unsubscribe();
  }
}
