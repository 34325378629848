import { Component, OnDestroy, OnInit } from '@angular/core';
import { Block } from '../models/content.model';
import { ContentService } from '../services/content/content.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { QuestionsService } from '../services/questions/questions.service';
import { Subscription } from 'rxjs';
import { translatedContent } from '../shared/data/translated-content';

@Component({
  selector: 'app-parent',
  templateUrl: './parent.component.html',
  styleUrls: ['./parent.component.scss'],
})
export class ParentComponent implements OnInit, OnDestroy {
  banner?: Block;
  blockContentLoaded = false;
  blocks?: Block[];
  californiaIcon?: Block;
  getBlocksSubscription!: Subscription;
  headerImg?: Block;
  heading?: Block;
  illinoisIcon?: Block;
  lang!: string;
  michiganIcon?: Block;
  ohioIcon?: Block;
  promoArea1?: Block;
  promoArea2?: Block;
  promoArea3?: Block;
  state!: string;
  stateContentLoaded = false;
  translatedContent: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private questionsService: QuestionsService,
    private router: Router,
    private routeHelperService: RouteHelperService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { lang, state } = this.routeHelperService.getRouteValues();
        this.lang = lang;
        this.state = state;

        this.getBlockContent();
      }
    });
  }

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;

    this.getBlockContent();
  }

  getBlockContent(): void {
    this.getBlocksSubscription = this.contentService
      .getBlocks(this.lang)
      .subscribe((data: { items: Block[] }) => {
        this.blocks = data.items;

        this.heading = data.items.find(
          (element) => element.system.codename === 'mcmc_home___all'
        );
        this.headerImg = data.items.find(
          (element) => element.system.codename === 'home_header_image___mcmc'
        );
        this.banner = data.items.find(
          (element) => element.system.codename === 'mcmc_home___banner'
        );
        this.californiaIcon = data.items.find(
          (element) => element.system.codename === 'california_icon_link___mcmc'
        );
        this.illinoisIcon = data.items.find(
          (element) => element.system.codename === 'illinois_icon_link___mcmc'
        );
        this.michiganIcon = data.items.find(
          (element) => element.system.codename === 'michigan_icon_link___mcmc'
        );
        this.ohioIcon = data.items.find(
          (element) => element.system.codename === 'ohio_icon_link___mcmc'
        );

        this.blockContentLoaded = true;

        if (this.state) this.getStateContent();
      });
  }

  getStateContent() {
    this.promoArea1 = this.blocks?.find(
      (element) =>
        element.system.codename === `home_promo_area_1___${this.state}`
    );
    this.promoArea2 = this.blocks?.find(
      (element) =>
        element.system.codename === `home_promo_area_2___${this.state}`
    );
    this.promoArea3 = this.blocks?.find(
      (element) =>
        element.system.codename === `home_promo_area_3___${this.state}`
    );
    this.heading = this.blocks?.find(
      (element) =>
        element.system.codename === `home_introduction___${this.state}`
    );
    this.headerImg = this.blocks?.find(
      (element) =>
        element.system.codename === `home_header_image___${this.state}`
    );

    this.stateContentLoaded = true;
  }

  resetQuestionnaire() {
    this.questionsService.resetAnswers();
  }

  ngOnDestroy(): void {
    if (this.getBlocksSubscription) this.getBlocksSubscription.unsubscribe();
  }
}
