import { Injectable } from '@angular/core';
import { CareModel, Page } from 'src/app/models/content.model';
import { includes } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  searchResultCareModels: CareModel[] = [];
  searchResultPages: Page[] = [];
  searchTerm!: string;

  constructor() {}

  createSearchResults(
    searchTerm: string,
    pages: Page[],
    careModels: CareModel[]
  ): void {
    this.searchTerm = searchTerm;
    this.searchResultCareModels = [];
    this.searchResultPages = [];

    pages.forEach((page) => {
      try {
        if (
          includes(page.body.value.toLowerCase(), searchTerm) ||
          includes(page.introduction.value.toLowerCase(), searchTerm) ||
          includes(page.title.value.toLowerCase(), searchTerm)
        )
          this.searchResultPages.push(page);
      } catch {
        console.error('There was an error searching this page. ', page);
      }
    });
    careModels.forEach((careModel) => {
      try {
        if (
          includes(careModel.body.value.toLowerCase(), searchTerm) ||
          includes(careModel.tab_2_body.value.toLowerCase(), searchTerm) ||
          includes(careModel.tab_3_body.value.toLowerCase(), searchTerm) ||
          includes(careModel.tab_4_body.value.toLowerCase(), searchTerm) ||
          includes(careModel.title.value.toLowerCase(), searchTerm)
        )
          this.searchResultCareModels.push(careModel);
      } catch {
        console.error(
          'There was an error searching this care model. ',
          careModel
        );
      }
    });
  }

  getSearchResults() {
    return {
      searchResultCareModels: this.searchResultCareModels,
      searchResultPages: this.searchResultPages,
      searchTerm: this.searchTerm,
    };
  }
}
