import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { includes } from 'lodash';
import {
  careModelCodeNames,
  careModelPathNames,
  pageCodeNames,
  pagePathNames,
  pageRoutes,
  understandPagePathNames,
} from 'src/app/shared/data/routes-and-codenames';

@Injectable({
  providedIn: 'root',
})
export class RouteHelperService {
  private validStates = ['ca', 'il', 'mi', 'oh'];

  constructor(private router: Router) {}

  /**
   * Takes lang, state, and path (final part of the url, ex. 'whats-covered')
   * and returns the codename for the page in Kentico.
   * @param lang
   * @param state
   * @param path
   */
  getCodeNameByState(lang: string, state: string, path: string) {
    const pageCodeName = state
      ? pageCodeNames[lang][state][path]
      : pageCodeNames[lang][path];

    return pageCodeName;
  }

  getCodeNameByUrl(lang: string, state: string, path: string) {
    const codeName = careModelCodeNames[state][path]
      ? careModelCodeNames[state][path]
      : pageCodeNames[lang][state][path];

    return codeName;
  }

  getCareModelUrlByCodeName(codename: string, state: string) {
    if (careModelPathNames[state][codename])
      return careModelPathNames[state][codename];
  }

  getPageUrlByCodeName(codename: string, state: string) {
    if (understandPagePathNames[state][codename])
      return understandPagePathNames[state][codename];
    if (pagePathNames[state][codename]) return pagePathNames[state][codename];
  }

  getRouteValues() {
    let path = this.router.url.split('/');
    const lang = path[1];
    const state = this.isStateValid(path[2]) ? path[2] : '';

    return { lang, state };
  }

  getFinalPathValue(): string {
    let pathArray = this.router.url.split('/');
    return pathArray[pathArray.length - 1]; // Get path without leading '/'
  }

  isStateValid(state: string) {
    return includes(this.validStates, state);
  }

  /**
   * @param url
   * @returns true if the url is a subpage of understand-my-care
   */
  isUnderstandSubpage(url: string): boolean {
    let urlArr = url.split('/');
    return urlArr[urlArr.length - 2] === pageRoutes.understandMyCare;
  }
}
