import { Component, OnInit, Input } from '@angular/core';
import { Question } from '../../../models/content.model';
import { QuestionsService } from '../../../services/questions/questions.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
  @Input() value!: number;
  questions!: Question[];
  unit!: number;

  constructor(private questionsService: QuestionsService) {}

  ngOnInit(): void {
    this.questions = this.questionsService.questions;
    this.unit = 100 / (this.questions.length + 1); // add 1 to account for the find-my-care component
  }
}
