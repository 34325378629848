import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouteHelperService } from 'src/app/services/route-helper/route-helper.service';
import { translatedContent } from '../../shared/data/translated-content';

@Component({
  selector: 'app-get-started-dialog',
  templateUrl: './get-started-dialog.component.html',
  styleUrls: ['./get-started-dialog.component.scss'],
})
export class GetStartedDialogComponent implements OnInit {
  lang!: string;
  translatedContent: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private routeHelperService: RouteHelperService,
    public dialogRef: MatDialogRef<GetStartedDialogComponent>
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang } = this.routeHelperService.getRouteValues();
    this.lang = lang;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
