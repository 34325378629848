<div *ngIf="question.reference_id" class="ncoa-container-padding">
  <app-progress-bar [value]="progressValue"></app-progress-bar>
  <h1 class="text-3xl font-serif text-center mt-4 md:mt-16 md:text-4xl">
    {{ question.untitled_text.value }}
  </h1>
  <div
    class="text-center mt-6 max-w-3xl mx-auto"
    [innerHtml]="question.body.value"
  ></div>
  <button
    class="link block text-center text-sm mt-8 mx-auto"
    (click)="openDialog()"
  >
    {{ question.prompt_title.value }}
  </button>

  <form [formGroup]="questionForm">
    <div class="hide-radio mt-6 flex flex-col lg:flex-row">
      <mat-radio-group
        class="flex flex-col lg:flex-row mx-auto"
        formControlName="question"
      >
        <mat-radio-button
          class="question-button"
          [value]="1"
          defaultTabIndex="0"
        >
          <div class="question-panel">
            <h2 class="text-xl font-serif">
              {{ question.answer_1_title.value }}
            </h2>
            <div
              class="mt-4 text-sm"
              [innerHtml]="question.answer_1_text.value"
            ></div>
          </div>
        </mat-radio-button>
        <mat-radio-button
          class="question-button"
          [value]="2"
          defaultTabIndex="0"
        >
          <div class="question-panel">
            <h2 class="text-xl font-serif">
              {{ question.answer_2_title.value }}
            </h2>
            <div
              class="mt-4 text-sm"
              [innerHtml]="question.answer_2_text.value"
            ></div>
          </div>
        </mat-radio-button>
        <mat-radio-button
          class="question-button"
          [value]="3"
          defaultTabIndex="0"
        >
          <div class="question-panel">
            <h2 class="text-xl font-serif">
              {{ question.answer_3_title.value }}
            </h2>
            <div
              class="mt-4 text-sm"
              [innerHtml]="question.answer_3_text.value"
            ></div>
          </div>
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div class="flex flex-col justify-center flex-wrap mt-12 md:flex-row">
      <button class="mx-2 btn btn-ghost-green-dark" (click)="onBackClick()">
        {{ translatedContent[lang].back }}
      </button>

      <a
        *ngIf="hasSeenResults"
        class="mx-2 mt-6 btn btn-ghost-green-dark text-center md:mt-0"
        [routerLink]="['/', lang, state, 'results']"
      >
        {{ translatedContent[lang].showResults }}
      </a>

      <button
        class="mx-2 mt-6 btn btn-green-dark md:mt-0"
        (click)="onNextClick()"
      >
        {{
          isLastQuestion
            ? translatedContent[lang].getResults
            : translatedContent[lang].next
        }}
      </button>
    </div>
  </form>
</div>
