import { Injectable } from '@angular/core';
import { Block } from '../../models/content.model';
import { find, includes, pull, startsWith } from 'lodash';
import { RouteHelperService } from '../route-helper/route-helper.service';
import { ToolTipMap } from 'src/app/models/tool-tip-map.model';
import { pageRoutes } from '../../shared/data/routes-and-codenames';

@Injectable({
  providedIn: 'root',
})
export class HtmlProcessingService {
  baseUrl = window.location.origin;

  constructor(private routeHelperService: RouteHelperService) {}

  /**
   * Add tool tip span elements to any text.
   */
  addToolTips(
    html: string,
    toolTipMap: ToolTipMap,
    toolTipKeys: string[]
  ): string {
    let parser = new DOMParser();
    let serializer = new XMLSerializer();
    let parsedHtml = parser.parseFromString(html, 'text/html');
    let paragraphs = parsedHtml.getElementsByTagName('p');

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of paragraphs which doesn't have a forEach method.
    for (let i = 0; i < paragraphs.length; i++) {
      let paragraph = paragraphs[i];
      const isCardElement = paragraph.classList.contains('js-kentico-card');
      const containsLink = !!paragraph.getElementsByTagName('a').length;

      if (!isCardElement && !containsLink) {
        const words = paragraph.innerHTML.split(' ');
        let replacedWords: string[] = [];

        words.forEach((word) => {
          let wordPushed = false;

          toolTipKeys.every((key) => {
            const regex = new RegExp(`(^${key}\\W)|(^${key}$)`, 'gi');

            if (word.match(regex)) {
              const toolTipText = this.createToolTipText(word, key, toolTipMap);

              replacedWords.push(toolTipText);

              // Pull key from toolTipKeys since we only want to match the
              // first occurrence of each key.
              pull(toolTipKeys, key);
              wordPushed = true;
              return false;
            }

            return true;
          });

          if (!wordPushed) replacedWords.push(word);
        });

        paragraph.innerHTML = replacedWords.join(' ');
      }
    }

    return serializer.serializeToString(parsedHtml);
  }

  /**
   * Add the ability to tab into and out of tool tips with links.
   */
  addToolTipKeyboardControl(): void {
    window.addEventListener(
      'load',
      function () {
        let toolTipFocusedBefore!: any;

        document.addEventListener('keyup', function (e: any) {
          let isTabPressed = e.key === 'Tab' || e.keyCode === 9;
          let isEscPressed = e.key === 'Escape' || e.keyCode === 27;

          if (isTabPressed) {
            // If we're tabbing off of a tool tip, and not onto one of the
            // links inside of a tool tip.
            if (
              toolTipFocusedBefore &&
              toolTipFocusedBefore.classList.contains('js-tooltip') &&
              !document.activeElement?.classList.contains('js-tooltip-link')
            ) {
              toolTipFocusedBefore
                .querySelector('.js-tooltip-text')
                ?.classList.add('invisible');

              toolTipFocusedBefore = document.activeElement;
            }

            if (document.activeElement?.classList.contains('js-tooltip')) {
              document.activeElement
                .querySelector('.js-tooltip-text')
                ?.classList.remove('invisible');

              toolTipFocusedBefore = document.activeElement;
            }
          }

          if (isEscPressed) {
            if (document.activeElement?.classList.contains('js-tooltip')) {
              document.activeElement
                .querySelector('.js-tooltip-text')
                ?.classList.add('invisible');
            }

            if (document.activeElement?.classList.contains('js-tooltip-link')) {
              document.activeElement.parentElement?.parentElement?.classList.add(
                'invisible'
              );
            }
          }
        });

        document.addEventListener('click', function () {
          if (
            toolTipFocusedBefore &&
            toolTipFocusedBefore.classList.contains('js-tooltip') &&
            !document.activeElement?.classList.contains('js-tooltip-link')
          ) {
            toolTipFocusedBefore
              .querySelector('.js-tooltip-text')
              ?.classList.add('invisible');

            toolTipFocusedBefore = document.activeElement;
          }
        });
      },
      false
    );
  }

  /**
   * Create span element that will be used to replace the matching word.
   * Toggling of the invisible class on the tool tip text is handled in the
   * addToolTipKeyboardControl method. So make sure to leave the invisible class
   * there.
   */
  createToolTipText(word: string, key: string, toolTipMap: ToolTipMap): string {
    const { lang, state } = this.routeHelperService.getRouteValues();
    const definitionLength = toolTipMap[key].definition.length;
    const toolTipLength = toolTipMap[key].value.length;

    // If the definition is longer than the tooltip add a tooltip with a link
    // to the glossary.
    return definitionLength > toolTipLength
      ? `<span class="tooltip js-tooltip group" tabindex="0">
          ${word}
          <span class="tooltip-text js-tooltip-text invisible group-active:visible group-hover:visible" role="tooltip">
            ${toolTipMap[key].value}
            <span class="block text-center">
              <a href="/${lang}/${state}/${pageRoutes.understandMyCare}/${pageRoutes.glossary}" class="link-with-arrow white js-tooltip-link text-white text-center font-bold inline-block mt-2" tabindex="0">
                Full Definition
              </a>
            </span>
          </span>
        </span>`
      : `<span class="tooltip js-tooltip group" tabindex="0">
          ${word}
          <span class="tooltip-text js-tooltip-text invisible group-active:visible group-hover:visible" role="tooltip">
            ${toolTipMap[key].value}
          </span>
        </span>`;
  }

  /**
   * Turn anchor element into an internal link.
   */
  createInternalLink(anchor: HTMLAnchorElement): void {
    const { lang, state } = this.routeHelperService.getRouteValues();
    const linkProcessed =
      anchor.href.includes(lang) && anchor.href.includes(state);

    if (!linkProcessed) {
      const href = anchor.href.replace(`${this.baseUrl}/`, '');

      anchor.setAttribute('href', `/${lang}/${state}/${href}`);
      anchor.setAttribute(
        'ng-reflect-router-link',
        `/,${lang},${state},${href}`
      );
    }
  }

  /**
   * Remove the http:// from the beginning of Kentico phone number links.
   */
  fixPhoneNumberLinks(html: string): string {
    let parser = new DOMParser();
    let serializer = new XMLSerializer();
    let parsedHtml = parser.parseFromString(html, 'text/html');
    let anchors = parsedHtml.getElementsByTagName('a');

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of anchors which doesn't have a forEach method.
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];

      const isPhoneNumberLink = includes(anchor.href, 'tel:');

      if (isPhoneNumberLink) {
        anchor.href = anchor.href.replace('https://', '');
        anchor.href = anchor.href.replace('http://', '');
        anchor.href = anchor.href.replace('/', '');
      }
    }

    return serializer.serializeToString(parsedHtml);
  }

  /**
   * Add lang, state to all internal links from Kentico.
   */
  processKenticoAnchorsHtml(): void {
    let anchors = document.getElementsByTagName('a');

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of anchors which doesn't have a forEach method.
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];
      const isKenticoLink = anchor.classList.contains('js-kentico-link');
      const isInternalLink = startsWith(anchor.href, this.baseUrl);

      if (isInternalLink && isKenticoLink) {
        this.createInternalLink(anchor);
      }
    }
  }

  /**
   * Process Kentico HTML to add Kentico block objects as new elments into the
   * html in the places their corresponding object tags appear, and add the
   * js-kentico-link class to anchors so we can turn those into routerlinks.
   *
   * Returns the processed and serialized html string.
   */
  processKenticoHtml(html: string, blocks: Block[]): string {
    let parser = new DOMParser();
    let serializer = new XMLSerializer();
    let parsedHtml = parser.parseFromString(html, 'text/html');
    let objects = parsedHtml.getElementsByTagName('object');
    let anchors = parsedHtml.getElementsByTagName('a');

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of anchors which doesn't have a forEach method.
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];
      anchor.classList.add('js-kentico-link');
    }

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of objects which doesn't have a forEach method.
    for (let i = 0; i < objects.length; i++) {
      let element = objects[i];
      const codename: any = element.dataset.codename;
      let contentItem = find(blocks, ['system.codename', codename]);

      try {
        switch (contentItem?.type.value[0].name) {
          case 'Card':
            let card = this.createCardElement(contentItem);
            card && element.appendChild(card);
            break;
          case 'Default':
            let defaultItem = this.createDefaultElement(contentItem);
            defaultItem && element.appendChild(defaultItem);
            break;
          case 'Icon List':
            let iconListItem = this.createIconListElement(contentItem);
            iconListItem && element.appendChild(iconListItem);
            break;
        }
      } catch {
        console.error(
          "There was a problem creating the block content. More than likely it's missing a required value."
        );
      }
    }

    return serializer.serializeToString(parsedHtml);
  }

  createCardElement(contentItem: any) {
    let src, textContent;
    try {
      src = contentItem.image.value[0].url;
      textContent = contentItem.content.value;
      textContent = this.removeHtmlTags(textContent);
    } catch {
      console.error(
        "There was a problem creating the card. More than likely it's missing a required value."
      );
      return;
    }

    const a = document.createElement('a');
    const img = document.createElement('img');
    const p = document.createElement('p');
    const span = document.createElement('span');

    img.src = src;
    img.alt = textContent;
    p.className = 'text-center js-kentico-card';
    p.innerText = textContent;
    span.innerText = 'Opens in a new tab';

    // If this is a link to a document we need it to open in a new tab
    // and notify the user that it's going to do that.
    if (contentItem.document.value.length) {
      a.href = contentItem.document.value[0].url;
      a.className = 'info js-kentico-link inline-block link md:max-w-1/3';
      a.target = '_blank';
      a.appendChild(span);
    } else {
      a.href = contentItem.url.value;
      a.className = 'js-kentico-link inline-block link md:max-w-1/3';
    }

    a.appendChild(img);
    a.appendChild(p);

    return a;
  }

  createDefaultElement(contentItem: any) {
    let src, textContent;
    try {
      src = contentItem.image.value[0].url;
      textContent = contentItem.content.value;
      textContent = this.removeHtmlTags(textContent);
    } catch {
      console.error(
        "There was a problem creating the card. More than likely it's missing a required value."
      );
      return;
    }

    const img = document.createElement('img');
    img.className = 'mx-auto my-6';
    img.src = src;
    img.alt = textContent;

    return img;
  }

  createIconListElement(contentItem: any) {
    let src, textContent;
    try {
      src = contentItem.icon.value[0].url;
      textContent = contentItem.content.value;
    } catch {
      console.error(
        "There was a problem creating the icon list element. More than likely it's missing a required value."
      );
      return;
    }

    const div = document.createElement('div');
    const img = document.createElement('img');
    const p = document.createElement('p');

    div.className = 'flex items-center mb-4';
    img.className = 'w-10 mr-6';
    img.src = src;
    img.alt = this.removeHtmlTags(textContent);
    p.innerHTML = textContent;

    div.appendChild(img);
    div.appendChild(p);

    return div;
  }

  removeHtmlTags(html: string) {
    return html.replace(/(<([^>]+)>)/gi, '');
  }
}
