<div
  class="md:flex"
  [ngClass]="isUnderstandSubpage ? 'ncoa-container' : 'ncoa-container-padding'"
  [class.max-w-5xl]="!isUnderstandSubpage"
>
  <app-side-nav *ngIf="isUnderstandSubpage"></app-side-nav>
  <div
    *ngIf="!contentLoaded"
    [ngClass]="isUnderstandSubpage ? 'px-4 md:px-8' : ''"
    [class.max-w-4xl]="isUnderstandSubpage"
    class="kentico-kontent w-full mt-6 md:mt-10"
  >
    <ngx-skeleton-loader
      class="flex justify-center"
      [theme]="{
        height: '3.125rem',
        width: '50%'
      }"
    ></ngx-skeleton-loader>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div *ngIf="page && contentLoaded">
    <div
      [ngClass]="isUnderstandSubpage ? 'px-4 md:px-8' : ''"
      [class.max-w-4xl]="isUnderstandSubpage"
      class="kentico-kontent"
    >
      <h1
        [innerHtml]="page.title.value"
        [class.md:text-left]="isUnderstandSubpage"
        class="font-serif text-center text-3xl mt-4 md:mt-8 md:text-4xl"
      ></h1>
      <div
        [innerHtml]="page.introduction.value"
        class="mt-4 md:mt-8 md:text-lg lg:text-xl"
      ></div>
      <div [innerHtml]="page.body.value" class="mt-4 md:mt-8"></div>
    </div>
  </div>
</div>
