<footer
  class="bg-green-darkest text-white"
  [ngClass]="{
    'mt-12': state && !isUnderstandPage,
    'mt-6': !state && !isUnderstandPage
  }"
>
  <div class="ncoa-container-padding py-12">
    <div class="flex flex-col justify-between md:flex-row">
      <div class="flex items-center">
        <p *ngIf="lang">{{ translatedContent[lang].chooseYourState }}:</p>
        <div class="footer-divided-links hidden invisible md:block md:visible">
          <a
            (click)="resetQuestionnaire()"
            [routerLink]="['/', lang, 'ca']"
            [ngClass]="state === 'ca' ? 'underline' : ''"
            class="px-4 hover:underline"
            >California</a
          >
          <a
            (click)="resetQuestionnaire()"
            [routerLink]="['/', lang, 'il']"
            [ngClass]="state === 'il' ? 'underline' : ''"
            class="px-4 hover:underline"
            >Illinois</a
          >
          <a
            (click)="resetQuestionnaire()"
            [routerLink]="['/', lang, 'mi']"
            [ngClass]="state === 'mi' ? 'underline' : ''"
            class="px-4 hover:underline"
            >Michigan</a
          >
          <a
            (click)="resetQuestionnaire()"
            [routerLink]="['/', lang, 'oh']"
            [ngClass]="state === 'oh' ? 'underline' : ''"
            class="px-4 hover:underline"
            >Ohio</a
          >
        </div>

        <mat-form-field class="arrow-white ml-4 w-32 md:hidden md:invisible">
          <mat-select [(value)]="state" disableRipple>
            <mat-option value="" disabled>Select</mat-option>
            <mat-option value="ca" (click)="selectState('ca')"
              >California</mat-option
            >
            <mat-option value="mi" (click)="selectState('mi')">Michigan</mat-option>
            <mat-option value="oh" (click)="selectState('oh')">Ohio</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="state && ( state === 'ca' || state === 'oh' )" class="flex">
        <div class="hidden invisible md:block md:visible">
          <a
            *ngFor="let language of languages"
            (click)="resetQuestionnaire()"
            [routerLink]="state ? ['/', language.value, state] : ['/', language.value]"
            [attr.disabled]="lang === language.value"
            routerLinkActive="underline"
            class="language-link hover:underline"
          >
            {{ language.viewValue }}
          </a>
        </div>

        <mat-form-field class="arrow-white w-28 md:hidden md:invisible">
          <mat-select [value]="lang">
            <mat-option
              *ngFor="let language of languages"
              [value]="language.value"
              (click)="selectLang(language.value)"
              >{{ language.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div
      class="
        flex flex-col
        items-baseline
        justify-between
        mt-12
        md:flex-row md:items-end
      "
    >
      <div>
        <a class="info inline-block" href="https://ncoa.org/" target="_blank">
          <img src="/assets/NCOA_Logo_Footer.svg" class="logo mb-3" alt="NCOA Logo" />
          <span>Opens in a new tab</span>
        </a>
        <p class="text-sm">
          &copy; {{ currentYear }} National Council on Aging, Inc.
        </p>
      </div>

      <div>
        <div class="flex mt-8 mb-3 md:mt-0 md:justify-end">
          <a href="https://www.youtube.com/user/ncoaging" class="mr-8">
            <img src="/assets/Social_Icon_YouTube.svg" class="social" alt="YouTube Logo" />
          </a>

          <a href="https://twitter.com/NCOAging" class="mr-8">
            <img src="/assets/Social_Icon_Twitter.svg" class="social" alt="Twitter Logo" />
          </a>

          <a href="https://www.facebook.com/NCOAging/">
            <img src="/assets/Social_Icon_Facebook.svg" class="social" alt="Facebook Logo" />
          </a>
        </div>

        <div *ngIf="state && lang" class="footer-divided-links text-sm mt-2">
          <a
            [routerLink]="['/', lang, state, pageRoutes.terms]"
            routerLinkActive="underline"
            class="pr-4 hover:underline"
            >{{ translatedContent[lang].terms }}</a
          >
          <a
            [routerLink]="['/', lang, state, pageRoutes.privacy]"
            routerLinkActive="underline"
            class="px-4 hover:underline"
            >{{ translatedContent[lang].privacy }}</a
          >
          <a
            [routerLink]="['/', lang, state, pageRoutes.about]"
            routerLinkActive="underline"
            class="pl-4 hover:underline"
            >{{ translatedContent[lang].about }}</a
          >
        </div>

        <div *ngIf="!state && lang" class="footer-divided-links text-sm mt-2">
          <a
            [routerLink]="['/', lang, pageRoutes.terms]"
            routerLinkActive="underline"
            class="pr-4 hover:underline"
            >{{ translatedContent[lang].terms }}</a
          >
          <a
            [routerLink]="['/', lang, pageRoutes.privacy]"
            routerLinkActive="underline"
            class="px-4 hover:underline"
            >{{ translatedContent[lang].privacy }}</a
          >
          <a
            [routerLink]="['/', lang, pageRoutes.about]"
            routerLinkActive="underline"
            class="pl-4 hover:underline"
            >{{ translatedContent[lang].about }}</a
          >
        </div>
      </div>
    </div>
  </div>
</footer>
