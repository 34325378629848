import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content/content.service';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { CareModel, Page } from '../models/content.model';
import { find, sortBy } from 'lodash';
import { Subscription } from 'rxjs';
import { translatedContent } from '../shared/data/translated-content';

@Component({
  selector: 'app-care-choices',
  templateUrl: './care-choices.component.html',
  styleUrls: ['./care-choices.component.scss'],
})
export class CareChoicesComponent implements OnInit, OnDestroy {
  getCareModelsSubscription!: Subscription;
  getPageSubscription!: Subscription;
  careModels!: CareModel[];
  path!: string;
  lang!: string;
  page!: Page;
  state!: string;
  translatedContent: any;

  constructor(
    private contentService: ContentService,
    private routeHelperService: RouteHelperService
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.path = this.routeHelperService.getFinalPathValue();

    this.getCareModels();
    this.getPage();
  }

  getCareModels() {
    this.getCareModelsSubscription = this.contentService
      .getCareModels(this.lang, this.state)
      .subscribe((data: any) => {
        try {
          let careModels = data.items;
          this.careModels = sortBy(careModels, ['menu__order.value']);

          this.careModels.forEach((careModel) => {
            const codename = careModel.system.codename;
            let url = this.routeHelperService.getCareModelUrlByCodeName(
              codename,
              this.state
            );
            careModel.url = url;
          });
        } catch {
          console.error('There was a problem fetching care models.');
          return;
        }
      });
  }

  getPage() {
    const codename = this.routeHelperService.getCodeNameByUrl(
      this.lang,
      this.state,
      this.path
    );

    this.getPageSubscription = this.contentService
      .getPages(this.lang, this.state)
      .subscribe((res: any) => {
        try {
          this.page = find(res.items, ['system.codename', codename]);
        } catch {
          console.error('There was a problem fetching page.');
        }
      });
  }

  ngOnDestroy(): void {
    if (this.getCareModelsSubscription)
      this.getCareModelsSubscription.unsubscribe();
    if (this.getPageSubscription) this.getPageSubscription.unsubscribe();
  }
}
