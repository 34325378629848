<div class="ncoa-container-padding">
  <div class="bg-gray-lighter rounded-tl-4rem">
    <h1 class="bg-extended relative font-serif px-20 py-10">
      {{ translatedContent[lang].searchResultsFor }}:
      <span class="font-normal font-sans text-2xl text-green-dark"
        >&ldquo;{{ searchTerm }}&rdquo;</span
      >
    </h1>
    <div class="bg-white px-20 rounded-tl-4rem">
      <ul
        *ngIf="searchResultCareModels.length || searchResultPages.length"
        class="pt-6 max-w-4xl"
      >
        <ng-container *ngFor="let page of searchResultPages">
          <li *ngIf="page.url" class="search-result-item py-10">
            <a [routerLink]="page.url" class="link font-bold">{{
              page.title.value
            }}</a>
            <div [innerHtml]="page.introduction.value"></div>
          </li>
        </ng-container>

        <ng-container *ngFor="let careModel of searchResultCareModels">
          <li *ngIf="careModel.url" class="search-result-item py-10">
            <a [routerLink]="careModel.url" class="link font-bold">{{
              careModel.title.value
            }}</a>
            <div [innerHtml]="careModel.introduction.value"></div>
          </li>
        </ng-container>
      </ul>
      <div *ngIf="!searchResultCareModels.length && !searchResultPages.length">
        <p class="py-16">
          {{ translatedContent[lang].noResults }}
        </p>
      </div>
    </div>
  </div>
</div>
