/**
 * Service for getting content from kontent.ai
 * https://github.com/Kentico/kontent-delivery-sdk-js/blob/master/DOCS.md
 */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DeliveryClient, TypeResolver } from '@kentico/kontent-delivery';
import {
  Block,
  CareModel,
  Glossary,
  Page,
  Question,
} from '../../models/content.model';
import { Cache } from '../../models/cache.model';
import { EMPTY, Observable } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContentService {
  private readonly projectID = environment.kontentAiDeliveryKey;
  private _filteredCareModels: CareModel[] = [];

  constructor() {}

  deliveryClient = new DeliveryClient({
    projectId: this.projectID,
    typeResolvers: [
      new TypeResolver('block', () => new Block()),
      new TypeResolver('glossary', () => new Glossary()),
      new TypeResolver('careModel', () => new CareModel()),
      new TypeResolver('page', () => new Page()),
      new TypeResolver('question', () => new Question()),
    ],
  });

  /**
   * Memoize API calls
   * https://medium.com/globant/memoize-javascript-promises-for-performance-1c77117fb6b8
   * https://betterprogramming.pub/how-to-create-a-caching-service-for-angular-bfad6cbe82b0
   * @param fn Function
   * @returns memoized version of fn
   */
  memoize(fn: Function) {
    let cache: Cache = {};

    return (...args: Array<string>): Observable<any> => {
      let params = JSON.stringify(args);

      return params in cache
        ? cache[params]
        : (cache[params] = fn(...args).pipe(
            shareReplay(1),
            catchError((e) => {
              delete cache[params];
              return EMPTY;
            })
          ));
    };
  }

  get filteredCareModels() {
    return this._filteredCareModels;
  }

  setFilteredCareModels(filteredCareModels: CareModel[]) {
    this._filteredCareModels = filteredCareModels;
  }

  resetFilteredCareModels() {
    this._filteredCareModels = [];
  }

  private _getBlocks = (lang: string): Observable<any> => {
    return this.deliveryClient
      .items<Block>()
      .type('block')
      .equalsFilter('system.language', lang)
      .languageParameter(lang)
      .toObservable();
  };
  getBlocks = this.memoize(this._getBlocks);

  private _getCareModels = (lang: string, state?: string): Observable<any> => {
    return state
      ? this.deliveryClient
          .items<CareModel>()
          .type('care_model')
          .allFilter('elements.states', [state])
          .equalsFilter('system.language', lang)
          .languageParameter(lang)
          .toObservable()
      : this.deliveryClient
          .items<CareModel>()
          .type('care_model')
          .equalsFilter('system.language', lang)
          .languageParameter(lang)
          .toObservable();
  };
  getCareModels = this.memoize(this._getCareModels);

  private _getGlossaryItems = (
    lang: string,
    state: string
  ): Observable<any> => {
    return this.deliveryClient
      .items<Glossary>()
      .type('glossary')
      .allFilter('elements.states', [state])
      .equalsFilter('system.language', lang)
      .languageParameter(lang)
      .toObservable();
  };
  getGlossaryItems = this.memoize(this._getGlossaryItems);

  private _getPages = (lang: string, state?: string): Observable<any> => {
    return state
      ? this.deliveryClient
          .items<Page>()
          .type('page')
          .allFilter('elements.states', [state])
          .equalsFilter('system.language', lang)
          .languageParameter(lang)
          .toObservable()
      : this.deliveryClient
          .items<Page>()
          .type('page')
          .equalsFilter('system.language', lang)
          .languageParameter(lang)
          .toObservable();
  };
  getPages = this.memoize(this._getPages);

  private _getQuestions = (lang: string, state: string): Observable<any> => {
    return this.deliveryClient
      .items<Question>()
      .type('question')
      .allFilter('elements.states', [state])
      .equalsFilter('system.language', lang)
      .languageParameter(lang)
      .toObservable();
  };
  getQuestions = this.memoize(this._getQuestions);
}
