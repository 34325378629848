<div class="ncoa-container-padding">
  <div *ngIf="!firstQuestionSlug" class="w-full">
    <ngx-skeleton-loader
      [theme]="{
        height: '2rem',
        width: '100%'
      }"
    ></ngx-skeleton-loader>
  </div>
  <app-progress-bar *ngIf="firstQuestionSlug" [value]="1"></app-progress-bar>

  <div *ngIf="!page" class="max-w-3xl mx-auto mt-4 md:mt-16">
    <ngx-skeleton-loader
      class="flex justify-center"
      [theme]="{
        height: '3.125rem',
        width: '50%'
      }"
    ></ngx-skeleton-loader>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div *ngIf="page">
    <div>
      <h1 class="text-3xl font-serif text-center mt-4 md:mt-16 md:text-4xl">
        {{ page.title.value }}
      </h1>
      <p
        class="text-center mt-6 mb-8 max-w-3xl mx-auto"
        [innerHtml]="page.introduction.value"
      ></p>
    </div>

    <div
      *ngIf="hasFormSubmitted && !isFormValid"
      class="bg-pink px-10 py-4 text-white rounded-lg"
    >
      <span *ngIf="zipCodeForm.controls.zipCode.invalid" class="block">{{
        translatedContent[lang].zipCodeRequired
      }}</span>
      <span *ngIf="zipCodeForm.controls.county.invalid" class="block">{{
        translatedContent[lang].countyRequired
      }}</span>
      <span *ngIf="zipCodeForm.controls.age.invalid" class="block">{{
        translatedContent[lang].ageRequired
      }}</span>
      <span *ngIf="zipCodeForm.controls.medicare.invalid" class="block">{{
        translatedContent[lang].medicareRequired
      }}</span>
    </div>

    <form [formGroup]="zipCodeForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-col lg:flex-row">
        <div class="lg:w-1/2 lg:px-16">
          <h2
            [innerHtml]="page.summary.value"
            class="text-center font-serif mt-6"
          ></h2>
          <mat-radio-group
            class="flex flex-col font-bold mt-8 w-48 mx-auto"
            formControlName="medicare"
          >
            <mat-radio-button [value]="'yes'">{{
              translatedContent[lang].yesWillSoon
            }}</mat-radio-button>
            <mat-radio-button class="mt-6" [value]="'no'">{{
              translatedContent[lang].noDontKnow
            }}</mat-radio-button>
            <mat-error
              *ngIf="hasFormSubmitted && zipCodeForm.controls.medicare.invalid"
              class="text-xs"
              >{{ translatedContent[lang].medicareRequired }}</mat-error
            >
          </mat-radio-group>

          <div
            *ngIf="cards && cards.image.value[0]"
            class="mt-8 max-w-sm mx-auto"
          >
            <img [src]="cards.image.value[0].url" alt="Medicare Cards" />
            <div class="mt-8 text-center lg:text-left lg:pl-8">
              <button
                class="link view-cards-link"
                (click)="openCardsDialog()"
                type="button"
              >
                {{ translatedContent[lang].viewLarger }}
              </button>
            </div>
          </div>
        </div>

        <div class="lg:w-1/2 lg:px-16">
          <h2
            [innerHtml]="page.body.value"
            class="text-center font-serif mt-16 lg:mt-6"
          ></h2>
          <mat-form-field class="font-bold w-full mt-6" appearance="fill">
            <mat-label>{{ translatedContent[lang].yourZipCode }}</mat-label>
            <input matInput formControlName="zipCode" value="" />
            <mat-error *ngIf="zipCodeForm.controls.zipCode.invalid">{{
              translatedContent[lang].zipCodeRequired
            }}</mat-error>
          </mat-form-field>

          <mat-form-field class="font-bold w-full" appearance="fill">
            <mat-label>{{ translatedContent[lang].yourCounty }}</mat-label>
            <mat-select formControlName="county" disableRipple>
              <mat-option
                *ngFor="let county of counties"
                [value]="county.name"
                >{{ county.name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="zipCodeForm.controls.county.invalid">{{
              translatedContent[lang].countyRequired
            }}</mat-error>
          </mat-form-field>

          <mat-form-field class="font-bold w-full" appearance="fill">
            <mat-label>{{ translatedContent[lang].yourAge }}</mat-label>
            <mat-select formControlName="age" disableRipple>
              <mat-option *ngFor="let age of ages" [value]="age.value">{{
                age.name
              }}</mat-option>
            </mat-select>
            <mat-error *ngIf="zipCodeForm.controls.age.invalid">{{
              translatedContent[lang].ageRequired
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="flex flex-col justify-center flex-wrap mt-12 md:flex-row">
        <a
          *ngIf="hasSeenResults"
          class="mt-4 mx-2 btn btn-ghost-green-dark text-center md:mt-0"
          [routerLink]="['/', lang, state, 'results']"
        >
          {{ translatedContent[lang].showResults }}
        </a>

        <button
          class="mt-4 mx-2 btn btn-green-dark block md:mt-0"
          type="submit"
        >
          {{ translatedContent[lang].submit }}
        </button>
      </div>
    </form>

    <div class="text-center">
      <button
        *ngIf="hasSeenResults"
        class="mt-8 link-no-underline link-with-arrow"
        (click)="resetQuestionnaire()"
      >
        {{ translatedContent[lang].clear }}
      </button>
    </div>
  </div>
</div>
