import { ContentItem, Elements } from '@kentico/kontent-delivery';

export class Block extends ContentItem {
  public content!: Elements.RichTextElement;
  public document!: Elements.AssetsElement;
  public icon!: Elements.AssetsElement;
  public image!: Elements.AssetsElement;
  public states!: Elements.TaxonomyElement;
  public title!: Elements.TextElement;
  public type!: Elements.MultipleChoiceElement;
  public url!: Elements.TextElement;
}

export class Glossary extends ContentItem {
  public definition!: Elements.RichTextElement;
  public states!: Elements.TaxonomyElement;
  public term!: Elements.TextElement;
  public tooltip!: Elements.RichTextElement;
}

export class CareModel extends ContentItem {
  public body!: Elements.RichTextElement;
  public introduction!: Elements.RichTextElement;
  public summary!: Elements.RichTextElement;
  public tab_1_body!: Elements.RichTextElement;
  public tab_1_subtitle!: Elements.TextElement;
  public tab_1_title!: Elements.TextElement;
  public tab_2_body!: Elements.RichTextElement;
  public tab_2_subtitle!: Elements.TextElement;
  public tab_2_title!: Elements.TextElement;
  public tab_3_body!: Elements.RichTextElement;
  public tab_3_subtitle!: Elements.TextElement;
  public tab_3_title!: Elements.TextElement;
  public title!: Elements.TextElement;
}

export class Page extends ContentItem {
  public title!: Elements.TextElement;
  public body!: Elements.TextElement;
  public header_image!: Elements.TextElement;
  public introduction!: Elements.TextElement;
  public menu__label!: Elements.TextElement;
  public menu__order!: Elements.NumberElement;
  public pdf_file!: Elements.TextElement;
  public section_title!: Elements.TextElement;
  public summary!: Elements.TextElement;
}

export class Question extends ContentItem {
  public answer_1_text!: Elements.RichTextElement;
  public answer_1_title!: Elements.RichTextElement;
  public answer_2_text!: Elements.RichTextElement;
  public answer_2_title!: Elements.RichTextElement;
  public answer_3_text!: Elements.RichTextElement;
  public answer_3_title!: Elements.RichTextElement;
  public body!: Elements.RichTextElement;
  public prompt_text!: Elements.RichTextElement;
  public prompt_title!: Elements.TextElement;
  public reference_id!: Elements.TextElement;
  public slug!: Elements.UrlSlugElement;
  public states!: Elements.TaxonomyElement;
  public tooltip!: Elements.RichTextElement;
  public untitled_text!: Elements.TextElement;
}
