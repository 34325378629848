import { Component, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { ContentService } from '../services/content/content.service';
import { Page } from '../models/content.model';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { HtmlProcessingService } from '../services/html-processing/html-processing.service';
import { UtilityService } from '../services/utility/utility.service';
import { Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { cloneDeep, find } from 'lodash';
import { ToolTipMap } from '../models/tool-tip-map.model';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.scss'],
})
export class BasicPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  contentLoaded = false;
  getContentSubscription!: Subscription;
  href!: string;
  isUnderstandSubpage!: boolean;
  lang!: string;
  page!: Page | undefined;
  state!: string;
  toolTipKeys!: string[];
  toolTipMap!: ToolTipMap;

  constructor(
    private contentService: ContentService,
    private htmlProcessingService: HtmlProcessingService,
    private router: Router,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.href = this.routeHelperService.getFinalPathValue();
    this.isUnderstandSubpage = this.routeHelperService.isUnderstandSubpage(
      this.router.url
    );

    this.getContentSubscription = forkJoin({
      blocks: this.contentService.getBlocks(this.lang),
      glossary: this.contentService.getGlossaryItems(this.lang, this.state),
      page: this.contentService.getPages(this.lang, this.state),
    }).subscribe(
      ({ blocks, glossary, page }) => {
        try {
          this.createPage(page.items);
          this.toolTipMap = this.utilityService.createToolTipMap(
            glossary.items
          );
          this.toolTipKeys = Object.keys(this.toolTipMap);

          if (this.page?.body.value)
            this.page.body.value =
              this.htmlProcessingService.processKenticoHtml(
                this.page.body.value,
                blocks.items
              );

          // Fix Kentico phone number links.
          if (this.page?.body.value.toLowerCase().includes('tel:'))
            this.page.body.value =
              this.htmlProcessingService.fixPhoneNumberLinks(
                this.page.body.value
              );

          if (this.page?.introduction.value.toLowerCase().includes('tel:'))
            this.page.introduction.value =
              this.htmlProcessingService.fixPhoneNumberLinks(
                this.page.introduction.value
              );

          // Add tooltips.
          if (this.page?.introduction.value)
            this.page.introduction.value =
              this.htmlProcessingService.addToolTips(
                this.page.introduction.value,
                this.toolTipMap,
                this.toolTipKeys
              );

          if (this.page?.body.value)
            this.page.body.value = this.htmlProcessingService.addToolTips(
              this.page.body.value,
              this.toolTipMap,
              this.toolTipKeys
            );

          this.contentLoaded = true;
        } catch {
          console.error('There was a problem fetching page content.');
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  ngAfterViewChecked(): void {
    this.htmlProcessingService.processKenticoAnchorsHtml();
    this.utilityService.addEventListeners();
  }

  createPage(pages: Page[]): void {
    const pageCodeName = this.routeHelperService.getCodeNameByState(
      this.lang,
      this.state,
      this.href
    );

    if (pageCodeName) {
      this.page = cloneDeep(find(pages, ['system.codename', pageCodeName]));
    }
  }

  ngOnDestroy(): void {
    if (this.getContentSubscription) this.getContentSubscription.unsubscribe();
    this.utilityService.removeEventListeners();
  }
}
