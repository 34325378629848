import { Injectable } from '@angular/core';
import { Question } from '../../models/content.model';
import { find, findIndex, sortBy } from 'lodash';
import { Answer } from '../../models/questions.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { foundQuestion } from '../../models/found-question.model';

@Injectable({
  providedIn: 'root',
})
export class QuestionsService {
  private readonly _answers: {
    age: string;
    answers: Answer[];
    county: string;
    state: string;
    zip_code: string;
  } = {
    age: '',
    answers: [],
    county: '',
    state: '',
    zip_code: '',
  };
  private readonly baseUrl = environment.ncoaApiBaseUrl;
  private _lang: string = '';
  private _medicare: string = '';
  private _questions: Question[] = [];
  private _hasSeenResults = false;
  private _currentQuestionIndex!: number;

  constructor(private http: HttpClient) {}

  get questions() {
    return this._questions;
  }

  get isAnswersValid(): boolean {
    return (
      this._answers.age !== '' &&
      this._answers.county !== '' &&
      this._answers.zip_code !== ''
    );
  }

  /**
   * Add an answer property to each question in this._questions
   * and return the new array.
   */
  get questionsWithAnswers(): any {
    return this._questions.map((question) => {
      const questionNumberString = question.reference_id.value;
      let foundAnswer = find(this._answers.answers, {
        question: questionNumberString,
      });
      question.answer = {
        title: '',
        text: '',
      };

      if (foundAnswer) {
        const answerNumber = foundAnswer.answer;
        const answerTitle = question[`answer_${answerNumber}_title`].value;
        const answerText = question[`answer_${answerNumber}_text`].value;

        question.answer.title = answerTitle;
        question.answer.text = answerText;

        return question;
      }

      question.answer.title = 'You Skipped This Question';
      question.answer.text = '';
      return question;
    });
  }

  setAge(age: string) {
    this._answers.age = age;
  }

  get age() {
    return this._answers.age;
  }

  setCounty(county: string) {
    this._answers.county = county;
  }

  get county() {
    return this._answers.county;
  }

  setMedicare(medicare: string) {
    this._medicare = medicare;
  }

  get hasSeenResults() {
    return this._hasSeenResults;
  }

  setHasSeenResults() {
    this._hasSeenResults = true;
  }

  get medicare() {
    return this._medicare;
  }

  /**
   * Get the url slug of the next question in the questions array.
   * Only returns the sub-question slugs (questions with id that is not
   * a whole number, eg: 2.1) if the base question (questions with id that
   * is a whole number, eg: 2.0) was answered yes or maybe.
   * @returns { string }
   */
  getNextQuestionSlug(): any {
    const nextQuestionId =
      this._questions[this._currentQuestionIndex + 1].reference_id.value;
    const nextQuestionIsSubQuestion = !!(Number(nextQuestionId) % 1);
    const baseQuestionInt = Math.trunc(Number(nextQuestionId));
    const baseQuestionId = `${baseQuestionInt}.0`;

    if (!nextQuestionIsSubQuestion)
      return this._questions[this._currentQuestionIndex + 1].slug.value;

    const baseQuestionAnswer = find(this._answers.answers, {
      question: baseQuestionId,
    })?.answer;

    // If they answered yes or maybe, return the slug of the next question.
    if (baseQuestionAnswer !== 3)
      return this._questions[this._currentQuestionIndex + 1].slug.value;

    // Return the slug of the next base question, eg: 2.0, 3.0.
    let nextQuestion = find(this.questions, {
      reference_id: { value: `${baseQuestionInt + 1}.0` },
    });

    return nextQuestion?.slug.value;
  }

  /**
   * Get the url slug of the previous question in the questions array.
   * Only returns the sub-question slugs (questions with id that is not
   * a whole number, eg: 2.1) if the base question (questions with id that
   * is a whole number, eg: 2.0) was answered yes or maybe.
   * @returns { string }
   */
  getPrevQuestionSlug(): any {
    const prevQuestionId =
      this._questions[this._currentQuestionIndex - 1].reference_id.value;
    const prevQuestionIsSubQuestion = !!(Number(prevQuestionId) % 1);
    const baseQuestionInt = Math.trunc(Number(prevQuestionId));
    const baseQuestionId = `${baseQuestionInt}.0`;

    if (!prevQuestionIsSubQuestion)
      return this._questions[this._currentQuestionIndex - 1].slug.value;

    const baseQuestionAnswer = find(this._answers.answers, {
      question: baseQuestionId,
    })?.answer;

    // If they answered yes or maybe, return the slug of the previous question.
    if (baseQuestionAnswer !== 3)
      return this._questions[this._currentQuestionIndex - 1].slug.value;

    // Return the slug of the previous base question, eg: 2.0, 3.0.
    let prevQuestion = find(this.questions, {
      reference_id: { value: `${baseQuestionInt}.0` },
    });

    return prevQuestion?.slug.value;
  }

  getQuestionBySlug(slug: string): foundQuestion {
    let foundQuestion = {
      index: 0,
      question: new Question(),
    };

    this._questions.forEach((question, index) => {
      if (question.slug.value === slug) {
        foundQuestion.index = index;
        foundQuestion.question = question;

        this.setCurrentQuestionIndex(index);
      }
    });

    return foundQuestion;
  }

  getFirstQuestionSlug(lang: string, state: string) {
    if (
      this._questions[0] &&
      this._questions[0].slug &&
      this._answers.state === state &&
      this._lang === lang
    )
      return this._questions[0].slug.value;

    return '';
  }

  isFirstQuestion(): boolean {
    return this._currentQuestionIndex === 0;
  }

  isLastQuestion(): boolean {
    return this._currentQuestionIndex === this._questions.length - 1;
  }

  setCurrentQuestionIndex(index: number): void {
    this._currentQuestionIndex = index;
  }

  setQuestions(lang: string, questions: Question[]): void {
    let sortedQuestions = sortBy(questions, [
      (question) => question.system.name,
    ]);
    this._questions = sortedQuestions;
    this._lang = lang;
  }

  setAnswer(questionNumber: string, answerNumber: number): void {
    let answer: Answer = {
      question: questionNumber,
      answer: answerNumber,
    };

    if (find(this._answers.answers, { question: questionNumber })) {
      const questionIndex = findIndex(this._answers.answers, {
        question: questionNumber,
      });
      this._answers.answers[questionIndex].answer = answerNumber;
    } else {
      this._answers.answers.push(answer);
    }
  }

  getAnswer(questionNumber: string) {
    return find(this._answers.answers, { question: questionNumber });
  }

  get plans() {
    return this.http.get(
      `${this.baseUrl}/plans?model_id=ca_aco&zip_code=${this._answers.zip_code}&county=${this._answers.county}`
    );
  }

  get results() {
    return this.http.post(`${this.baseUrl}/questionnaire`, this._answers);
  }

  setState(state: string) {
    this._answers.state = state;
  }

  setZipCode(zipCode: string) {
    this._answers.zip_code = zipCode;
  }

  get zipCode() {
    return this._answers.zip_code;
  }

  resetAnswers() {
    this._medicare = '';
    this._answers.age = '';
    this._answers.answers = [];
    this._answers.county = '';
    this._answers.zip_code = '';
    this._hasSeenResults = false;
  }
}
