<div class="ncoa-container-padding">
  <div *ngIf="page">
    <h1 class="text-3xl font-serif text-center md:text-4xl">
      {{ page.title.value }}
    </h1>
    <p
      class="text-center mt-6 max-w-4xl mx-auto"
      [innerHtml]="page.introduction.value"
    ></p>
    <p
      class="text-center mt-6 max-w-4xl mx-auto"
      [innerHtml]="page.body.value"
    ></p>
  </div>

  <section
    *ngIf="careModels"
    class="mt-8 border border-green rounded-3xl overflow-hidden"
  >
    <ul class="my-care-results">
      <li *ngFor="let careModel of careModels">
        <a
          class="flex items-center p-4 md:py-10 lg:px-20"
          [routerLink]="['/', lang, state, careModel.url]"
        >
          <div class="w-10/12 md:w-2/3">
            <h2 class="link font-serif inline-block lg:text-2xl">
              {{ careModel.title.value }}
            </h2>
            <p
              class="mt-6 hidden invisible md:visible md:block"
              [innerHtml]="careModel.summary.value"
            ></p>
          </div>
          <div class="w-1/3 flex justify-end">
            <p class="btn btn-green-dark hidden invisible md:block md:visible">
              {{ translatedContent[lang].viewChoices }}
            </p>
            <p class="link link-with-arrow mr-6 h-4 md:hidden md:invisible"></p>
          </div>
        </a>
      </li>
    </ul>
  </section>
</div>
