import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content/content.service';
import { HtmlProcessingService } from '../services/html-processing/html-processing.service';
import { UtilityService } from '../services/utility/utility.service';
import { ActivatedRoute } from '@angular/router';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { forkJoin, Subscription } from 'rxjs';
import { Block, Page } from '../models/content.model';
import { MenuItem } from '../models/menu-item.model';
import { cloneDeep, find } from 'lodash';
import { translatedContent } from '../shared/data/translated-content';

@Component({
  selector: 'app-understand-my-care',
  templateUrl: './understand-my-care.component.html',
  styleUrls: ['./understand-my-care.component.scss'],
})
export class UnderstandMyCareComponent implements OnInit, OnDestroy {
  getContentSubscription!: Subscription;
  href!: string;
  lang!: string;
  page!: Page | undefined;
  state!: string;
  translatedContent: any;
  understandMenuItems: MenuItem[] = [];

  constructor(
    public activatedRoute: ActivatedRoute,
    private contentService: ContentService,
    private htmlProcessingService: HtmlProcessingService,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.href = this.routeHelperService.getFinalPathValue();

    this.getContentSubscription = forkJoin({
      blocks: this.contentService.getBlocks(this.lang),
      pages: this.contentService.getPages(this.lang, this.state),
    }).subscribe(
      ({ blocks, pages }) => {
        try {
          this.createPage(pages.items);
          this.understandMenuItems = this.utilityService.createMenuItems(
            pages.items,
            this.lang,
            this.state
          );

          if (this.page?.body.value)
            this.page.body.value =
              this.htmlProcessingService.processKenticoHtml(
                this.page.body.value,
                blocks.items
              );
        } catch {
          console.error('There was a problem fetching page content.');
        }
      },
      (e) => {
        console.error(e);
      }
    );
  }

  createPage(pages: Page[]): void {
    const pageCodeName = this.routeHelperService.getCodeNameByState(
      this.lang,
      this.state,
      this.href
    );

    if (pageCodeName) {
      this.page = cloneDeep(find(pages, ['system.codename', pageCodeName]));
    }
  }

  ngOnDestroy(): void {
    if (this.getContentSubscription) this.getContentSubscription.unsubscribe();
  }
}
