import { languageRoutes } from './routes-and-codenames';

export let translatedContent: any = {
  [languageRoutes.english]: {
    about: 'About Us',
    ageRequired: 'The age field is required',
    answerQuestions: 'Answer Questions',
    back: 'Back',
    chooseYourState: 'Choose Your State',
    clear: 'Clear/Reset',
    close: 'Close',
    counselorTools: 'Counselor Tools',
    countiesServed: 'Counties Served',
    countyRequired: 'The county field is required',
    dontSeeYourState: "Don't see your state?",
    email: 'Email',
    getHelp: 'Get Help!',
    getResults: 'Get Results',
    getStarted: 'Get Started',
    invalidZip: 'That ZIP code is not valid. Please try another ZIP code.',
    learnMore: 'Learn More',
    medicareRequired: 'Please confirm you have Medicare and Medicaid',
    menu: 'Menu',
    myAnswers: 'My Answers',
    next: 'Next',
    noDontKnow: "No / I don't know",
    noResults:
      'There are no results for that search term. Please try a broader term to widen your search.',
    organization: 'Organization',
    phone: 'Phone',
    privacy: 'Privacy Policy',
    search: 'Search',
    searchFor: 'Search For',
    searchResultsFor: 'Search Results For',
    searchTermRequired: 'Search Term is Required',
    showNow: 'Show Now',
    showResults: 'Show Results',
    submit: 'Submit',
    terms: 'Terms of Use',
    viewChoices: 'View Choices',
    viewLarger: 'View Larger',
    whyAreWeAsking: 'Why are we asking this question?',
    wrongState: {
      ca: 'You must enter a zip code in California',
      mi: 'You must enter a zip code in Michigan',
      oh: 'You must enter a zip code in Ohio',
    },
    yesWillSoon: 'Yes / I will soon',
    yourAge: 'Your Age',
    yourCounty: 'Your County',
    yourZipCode: 'Your Zip Code',
    zipCodeRequired: 'The zip code field is required',
  },
  [languageRoutes.spanish]: {
    about: 'Sobre nosotros',
    ageRequired: 'El campo de edad es obligatorio',
    answerQuestions: 'Responder preguntas',
    back: 'Regresar',
    chooseYourState: 'Elija su estado',
    clear: 'Borrar/Restablecer',
    close: 'Cerrar',
    counselorTools: 'Herramientas del consejero',
    countiesServed: 'Condados atendidos',
    countyRequired: 'El campo del condado es obligatorio',
    dontSeeYourState: '¿No encuentra su estado?',
    email: 'Correo electrónico',
    getHelp: 'Obtenga ayuda',
    getResults: 'Obtener resultados',
    getStarted: 'Comenzar',
    invalidZip: 'That ZIP code is not valid. Please try another ZIP code.',
    learnMore: 'Más información',
    medicareRequired: 'Confirme que tiene Medicare y Medicaid',
    menu: 'Menú',
    myAnswers: 'Mis respuestas',
    next: 'Siguiente',
    noDontKnow: 'No / No lo sé',
    noResults:
      'No hay resultados para ese término de búsqueda. Intente usar un término más amplio para extender la búsqueda.',
    organization: 'Organización',
    phone: 'Teléfono',
    privacy: 'Política de privacidad',
    search: 'Buscar',
    searchFor: 'Buscar',
    searchResultsFor: 'Buscar resultados para',
    searchTermRequired: 'El término que busca es necesario',
    showNow: 'Mostrar ahora',
    showResults: 'Mostrar resultados',
    submit: 'Enviar',
    terms: 'Términos de uso',
    viewChoices: 'Ver opciones',
    viewLarger: 'Vista ampliada',
    whyAreWeAsking: '¿Por qué hacemos esta pregunta?',
    wrongState: {
      ca: 'Debe ingresar un código postal en California',
      oh: 'Debe ingresar un código postal en Ohio',
    },
    yesWillSoon: 'Sí / Lo tendré pronto',
    yourAge: 'Su edad',
    yourCounty: 'Su condado',
    yourZipCode: 'Su código postal',
    zipCodeRequired: 'El campo del código postal es obligatorio',
  },
  [languageRoutes.chinese]: {
    about: '关于我们',
    ageRequired: '年龄字段必填',
    answerQuestions: '回答問題',
    back: '返回',
    chooseYourState: '选择您所在州',
    clear: '清除/重置',
    close: '关闭',
    counselorTools: '顾问工具',
    countiesServed: '所服务的县',
    countyRequired: '县字段必填',
    dontSeeYourState: '无法找到您的州？',
    email: '电子邮箱',
    getHelp: '获取帮助！',
    getResults: '获取结果',
    getStarted: '开始',
    invalidZip: 'That ZIP code is not valid. Please try another ZIP code.',
    learnMore: '深入了解',
    medicareRequired: '请确认您已投保 Medicare 和 Medicaid',
    menu: '菜单',
    myAnswers: '我的回答',
    next: '下一步',
    noDontKnow: '否/我不知道',
    noResults: '该搜索词汇未返回结果。请尝试更宽泛的词汇，以扩大搜索范围。',
    organization: '组织',
    phone: '电话',
    privacy: '隐私政策',
    search: '搜索',
    searchFor: '搜索',
    searchResultsFor: '搜索结果',
    searchTermRequired: '搜索词必填',
    showNow: '立即显示',
    showResults: '显示结果',
    submit: '提交',
    terms: '使用条款',
    viewChoices: '查看选择',
    viewLarger: '放大查看',
    whyAreWeAsking: '我们为何询问这个问题？',
    wrongState: {
      ca: '您必须在 California 输入一个邮政编码',
      oh: '您必须在 Ohio 输入一个邮政编码',
    },
    yesWillSoon: '是/我尽快',
    yourAge: '年龄',
    yourCounty: '所在县',
    yourZipCode: '您的邮政编码',
    zipCodeRequired: '邮政编码字段必填',
  },
  [languageRoutes.chineseTaiwan]: {
    about: '關於我們',
    ageRequired: '年齡欄位為必填',
    answerQuestions: '回答问题',
    back: '上一頁',
    chooseYourState: '選擇您所在的州',
    clear: '清除/重設',
    close: '關閉',
    counselorTools: '顧問工具',
    countiesServed: '所服務的郡',
    countyRequired: '郡欄位為必填',
    dontSeeYourState: '「沒有看見您所在的州？」',
    email: '電子郵件',
    getHelp: '獲得協助！',
    getResults: '獲得結果',
    getStarted: '開始',
    invalidZip: 'That ZIP code is not valid. Please try another ZIP code.',
    learnMore: '深入瞭解',
    medicareRequired: '請確認您同時擁有 Medicare 和 Medicaid',
    menu: '功能表',
    myAnswers: '我的答案',
    next: '下一頁',
    noDontKnow: '否/我不知道',
    noResults: '此搜尋字詞沒有結果。請嘗試範圍較廣的條件，以擴大您的搜尋。',
    organization: '組織',
    phone: '電話',
    privacy: '隱私權政策',
    search: '搜尋',
    searchFor: '搜尋',
    searchResultsFor: '搜尋結果',
    searchTermRequired: '搜尋字詞為必填',
    showNow: '立即顯示',
    showResults: '顯示結果',
    submit: '提交',
    terms: '使用條款',
    viewChoices: '檢視選擇',
    viewLarger: '放大檢視',
    whyAreWeAsking: '我們為何詢問此問題？',
    wrongState: {
      ca: '您必須在 California 輸入一個郵遞區號',
      oh: '您必須在 Ohio 輸入一個郵遞區號',
    },
    yesWillSoon: '是/我盡快',
    yourAge: '您的年齡',
    yourCounty: '您所在的郡',
    yourZipCode: '您的郵遞區號',
    zipCodeRequired: '郵遞區號欄位為必填',
  },
};
