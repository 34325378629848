import { Component, OnInit } from '@angular/core';
import { HtmlProcessingService } from './services/html-processing/html-processing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isSearch = false;
  title = 'mcmc-web';

  constructor(private htmlProcessingService: HtmlProcessingService) {}

  ngOnInit(): void {
    this.htmlProcessingService.addToolTipKeyboardControl();
  }

  toggleSearch = (): void => {
    this.isSearch = !this.isSearch;
  };
}
