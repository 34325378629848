import { Component, OnDestroy, OnInit } from '@angular/core';
import { MenuItem } from '../../../models/menu-item.model';
import { Subscription } from 'rxjs';
import { ContentService } from '../../../services/content/content.service';
import { Page } from '../../../models/content.model';
import { UtilityService } from '../../../services/utility/utility.service';
import { RouteHelperService } from '../../../services/route-helper/route-helper.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, OnDestroy {
  getPagesSubscription!: Subscription;
  lang!: string;
  pages!: Page[];
  state!: string;
  understandMenuItems: MenuItem[] = [];

  constructor(
    private contentService: ContentService,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;

    this.getPages();
  }

  // TODO: move to a higher component and cache pages. Use the cached version here.
  getPages() {
    this.getPagesSubscription = this.contentService
      .getPages(this.lang, this.state)
      .subscribe(
        (res: any) => {
          try {
            this.pages = res.items;
            this.understandMenuItems = this.utilityService.createMenuItems(
              this.pages,
              this.lang,
              this.state
            );
          } catch {
            console.error('There was a problem fetching pages.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  ngOnDestroy(): void {
    if (this.getPagesSubscription) this.getPagesSubscription.unsubscribe();
  }
}
