import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionsService } from '../../services/questions/questions.service';
import { ZipCodeFormService } from '../../services/zipcode-form/zipcode-form.service';
import { County } from '../../models/questions.model';
import { Plan } from 'src/app/models/plan.model';
import { translatedContent } from '../../shared/data/translated-content';
import { RouteHelperService } from '../../services/route-helper/route-helper.service';
import { ContentService } from 'src/app/services/content/content.service';
import { Block } from '../../models/content.model';
import { Subscription } from 'rxjs';
import { HtmlProcessingService } from 'src/app/services/html-processing/html-processing.service';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { orderBy } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../shared/components/dialog/dialog.component';

@Component({
  selector: 'app-care-choices-by-zip',
  templateUrl: './care-choices-by-zip.component.html',
  styleUrls: ['./care-choices-by-zip.component.scss'],
})
export class CareChoicesByZipComponent implements OnInit, OnDestroy {
  @Input() codename!: string;
  @Input() isAco!: boolean;
  @Input() bodyValue!: string;
  counties: County[] = [];
  getBlocksSubscription!: Subscription;
  getCountiesSubscription!: Subscription;
  getPlansSubscription!: Subscription;
  lang!: string;
  plans: Plan[] = [];
  resultsMessageBlock!: Block | undefined | null;
  state!: string;
  tooManyResultsLimit = 50;
  translatedContent: any;
  zipCodeForm!: FormGroup;

  constructor(
    private contentService: ContentService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private htmlProcessingService: HtmlProcessingService,
    private questionsService: QuestionsService,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService,
    private zipCodeService: ZipCodeFormService
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    this.counties = this.zipCodeService.counties;

    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;

    this.setupForm();
    this.getPlans();
  }

  getResultsBlock(numResults: number): void {
    if (this.getBlocksSubscription) this.getBlocksSubscription.unsubscribe();
    const codename = !numResults
      ? 'no_plans_available'
      : `plans_too_many_message___${this.state}`;

    this.getBlocksSubscription = this.contentService
      .getBlocks(this.lang)
      .subscribe(
        (data: { items: Block[] }) => {
          try {
            this.resultsMessageBlock = data.items.find(
              (element) => element.system.codename === codename
            );

            if (this.resultsMessageBlock?.content.value)
              this.resultsMessageBlock.content.value =
                this.htmlProcessingService.processKenticoHtml(
                  this.resultsMessageBlock.content.value,
                  data.items
                );
          } catch {
            console.error('There was a problem fetching blocks.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getPlans() {
    const isFormValid = this.zipCodeForm.valid;

    if (isFormValid) {
      if (this.getPlansSubscription) this.getPlansSubscription.unsubscribe();
      const zipCode = this.zipCodeForm.get('zipCode')?.value;
      const county = this.zipCodeForm.get('county')?.value;

      this.getPlansSubscription = this.zipCodeService
        .getPlans(this.codename, zipCode, county)
        .subscribe(
          (res: any) => {
            try {
              const numberOfPlans = res.meta.count;
              if (!numberOfPlans) {
                this.plans.length = numberOfPlans;
                this.getResultsBlock(numberOfPlans);
              } else {
                if (numberOfPlans > this.tooManyResultsLimit) {
                  this.getResultsBlock(numberOfPlans);
                } else {
                  this.resultsMessageBlock = null;
                }
                let plans = res.data.map((plan: any) => {
                  // Add url prefix if it's missing
                  let url = plan.attributes.url;
                  url =
                    url.startsWith('https://') || url.startsWith('http://')
                      ? url
                      : `https://${url}`;

                  return {
                    contract: plan.attributes.contract_id,
                    counties: plan.attributes.counties_served,
                    email: plan.attributes.email,
                    id: plan.attributes.plan_id,
                    name: plan.attributes.name,
                    organization: plan.attributes.organization,
                    phone: plan.attributes.phone,
                    ranking: plan.attributes.ranking,
                    tty: plan.attributes.tty,
                    url,
                  };
                });
                this.plans = orderBy(plans, ['ranking'], ['desc']);
              }
            } catch {
              console.error('There was a problem fetching plans.');
            }
          },
          (e) => {
            console.error(e.error);
          }
        );
    }
  }

  openErrorDialog(message: string): void {
    this.dialog.open(DialogComponent, {
      data: {
        lang: this.lang,
        message,
        type: 'error',
        state: this.state,
        translatedContent: this.translatedContent,
      },
    });
  }

  openWrongStateDialog(): void {
    this.dialog.open(DialogComponent, {
      data: {
        lang: this.lang,
        type: 'wrongState',
        state: this.state,
        translatedContent: this.translatedContent,
      },
    });
  }

  setupForm() {
    this.zipCodeForm = this.fb.group({
      county: [
        this.questionsService.county ? this.questionsService.county : null,
        Validators.required,
      ],
      zipCode: [
        this.questionsService.zipCode ? this.questionsService.zipCode : null,
        [
          // TODO: Add number validator to zipCode
          Validators.required,
          Validators.minLength(this.zipCodeService.zipCodeLength),
          Validators.maxLength(this.zipCodeService.zipCodeLength),
        ],
      ],
    });

    this.zipCodeForm
      .get('zipCode')
      ?.valueChanges.pipe(debounceTime(this.zipCodeService.requestDelay))
      .subscribe((zipCode) => {
        const isZipCodeValid = this.zipCodeForm.controls.zipCode.valid;

        if (isZipCodeValid) {
          if (this.getCountiesSubscription)
            this.getCountiesSubscription.unsubscribe();
          this.questionsService.setZipCode(zipCode);

          this.getCountiesSubscription = this.zipCodeService
            .getCountiesByZip(zipCode)
            .subscribe(
              (res: any) => {
                try {
                  if (
                    res.data[0].attributes.state_code.toLowerCase() !==
                    this.state
                  ) {
                    this.counties = [];
                    this.zipCodeForm.controls.county.setValue(null);
                    this.questionsService.setZipCode(zipCode);
                    this.questionsService.setCounty('');
                    this.openWrongStateDialog();
                  } else {
                    let counties = res.data.map((county: any) => {
                      return {
                        id: county.id,
                        name: county.attributes.name,
                      };
                    });

                    this.counties = counties;
                    this.zipCodeService.setCounties(this.counties);
                    this.zipCodeForm.get('county')?.setValue(counties[0].name);
                  }
                } catch {
                  console.error('There was a problem fetching counties.');
                }
              },

              (e) => {
                this.counties = [];
                this.zipCodeForm.controls.county.setValue(null);
                this.questionsService.setZipCode(zipCode);
                this.questionsService.setCounty('');
                this.openErrorDialog(e.error);
              }
            );
        } else {
          this.questionsService.setZipCode('');
        }
      });

    this.zipCodeForm.get('county')?.valueChanges.subscribe((county: string) => {
      this.questionsService.setCounty(county);
    });
  }

  onSubmit() {
    this.getPlans();
  }

  ngAfterViewChecked(): void {
    this.htmlProcessingService.processKenticoAnchorsHtml();
    this.utilityService.addEventListeners();
  }

  ngOnDestroy() {
    if (this.getBlocksSubscription) this.getBlocksSubscription.unsubscribe();
    if (this.getPlansSubscription) this.getPlansSubscription.unsubscribe();
    if (this.getCountiesSubscription)
      this.getCountiesSubscription.unsubscribe();
    this.utilityService.removeEventListeners();
  }
}
