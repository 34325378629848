import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CareModel, Page } from '../models/content.model';
import { ContentService } from '../services/content/content.service';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { SearchService } from '../services/search/search.service';
import { pageRoutes } from '../shared/data/routes-and-codenames';
import { translatedContent } from '../shared/data/translated-content';

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss'],
})
export class SearchFormComponent implements OnInit, OnDestroy {
  @Input() toggleSearch!: Function;
  careModels!: CareModel[];
  getCareModelsSubscription!: Subscription;
  getPageSubscription!: Subscription;
  isFormValid!: boolean;
  lang!: string;
  pages!: Page[];
  searchForm!: FormGroup;
  searchTerm!: string;
  state!: string;
  translatedContent: any;

  constructor(
    private contentService: ContentService,
    private fb: FormBuilder,
    private routeHelperService: RouteHelperService,
    private router: Router,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;

    if (this.state) {
      this.getCareModels();
      this.getPages();
    }

    this.setupForm();
  }

  getCareModels(): void {
    this.getCareModelsSubscription = this.contentService
      .getCareModels(this.lang, this.state)
      .subscribe(
        (res: any) => {
          if (res.items) {
            try {
              this.careModels = res.items;
            } catch {
              console.error('There was a problem fetching care models.');
            }
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getPages(): void {
    this.getPageSubscription = this.contentService
      .getPages(this.lang, this.state)
      .subscribe(
        (res: any) => {
          try {
            this.pages = res.items;
          } catch {
            console.error('There was a problem fetching pages.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  // TODO: add validator to searchTerm.
  setupForm(): void {
    this.searchForm = this.fb.group({
      searchTerm: ['', Validators.required],
    });

    this.searchForm.get('searchTerm')?.valueChanges.subscribe((searchTerm) => {
      this.searchTerm = searchTerm;
    });

    this.searchForm.valueChanges.subscribe(() => {
      this.isFormValid = this.searchForm.valid;
    });
  }

  onSubmit(): void {
    if (this.isFormValid) {
      const searchTerm = this.searchTerm.toLowerCase();
      this.searchService.createSearchResults(
        searchTerm,
        this.pages,
        this.careModels
      );

      this.toggleSearch();
      this.router.navigate(['/', this.lang, this.state, pageRoutes.search]);
    }
  }

  ngOnDestroy(): void {
    if (this.getCareModelsSubscription)
      this.getCareModelsSubscription.unsubscribe();
    if (this.getPageSubscription) this.getPageSubscription.unsubscribe();
  }
}
