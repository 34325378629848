// All language route strings used in the app.
export let languageRoutes: any = {
  chinese: 'zh-cn',
  chineseTaiwan: 'zh-tw',
  english: 'en',
  spanish: 'es',
};
// All page route strings used in the app.
export let pageRoutes: any = {
  about: 'about',
  acos: 'acos',
  calMediconnect: 'cal-mediconnect',
  careChoices: 'care-choices',
  counselorTools: 'for-counselors',
  dualEligibility: 'dual-eligibility',
  dsnp: 'd-snp',
  fidesnp: 'fide-snp',
  findMyCare: 'find-my-care',
  findTheRightCare: 'find-the-right-care-choice',
  getHelp: 'get-help',
  glossary: 'glossary',
  healthchoice: 'healthchoice',
  learnMore: 'learn-more',
  ma: 'ma',
  medicare: 'medicare',
  medimedi: 'medimedi',
  makingTheMost: 'making-the-most-of-your-coverage',
  miHealthLink: 'mi-health-link',
  mltssProgram: 'mltss-program',
  newToMedicare: 'new-to-medicare',
  ohMycare: 'oh-mycare',
  pace: 'pace',
  privacy: 'privacy-policy',
  results: 'results',
  search: 'search',
  terms: 'terms-conditions',
  understandMyCare: 'understand-my-care',
  whatsCovered: 'whats-covered',
};
// Mapping of care model pathnames from app-routing.module to kontent.ai care model codenames.
export let careModelCodeNames: any = {
  ca: {
    [pageRoutes.acos]: 'ca_aco',
    [pageRoutes.calMediconnect]: 'ca_cmc',
    [pageRoutes.dsnp]: 'ca_d_snp',
    [pageRoutes.fidesnp]: 'ca_fide_snp',
    [pageRoutes.ma]: 'ca_ma',
    [pageRoutes.pace]: 'ca_pace',
  },
  il: {
    [pageRoutes.acos]: 'il_aco',
    [pageRoutes.healthchoice]: 'il_healthchoice',
    [pageRoutes.ma]: 'il_ma',
    [pageRoutes.medimedi]: 'il_original',
    [pageRoutes.mltssProgram]: 'il_mltss',
    [pageRoutes.pace]: 'il_pace',
  },
  mi: {
    [pageRoutes.acos]: 'mi_acos',
    [pageRoutes.dsnp]: 'mi_d_snp',
    [pageRoutes.pace]: 'mi_pace',
    [pageRoutes.ma]: 'mi_ma',
    [pageRoutes.medicare]: 'mi_original',
    [pageRoutes.miHealthLink]: 'mi_healthlink',
  },
  oh: {
    [pageRoutes.acos]: 'oh_aco',
    [pageRoutes.pace]: 'oh_pace',
    [pageRoutes.dsnp]: 'oh_d_snp',
    [pageRoutes.ma]: 'oh_ma',
    [pageRoutes.ohMycare]: 'oh_mycare',
  },
};
// Mapping of kontent.ai care model codenames to pathnames used in app-routing.module.
export let careModelPathNames: any = {
  ca: {
    ca_aco: pageRoutes.acos,
    ca_cmc: pageRoutes.calMediconnect,
    ca_d_snp: pageRoutes.dsnp,
    ca_fide_snp: pageRoutes.fidesnp,
    ca_ma: pageRoutes.ma,
    ca_pace: pageRoutes.pace,
  },
  il: {
    il_aco: pageRoutes.acos,
    il_healthchoice: pageRoutes.healthchoice,
    il_ma: pageRoutes.ma,
    il_original: pageRoutes.medimedi,
    il_mltss: pageRoutes.mltssProgram,
    il_pace: pageRoutes.pace,
  },
  mi: {
    mi_acos: pageRoutes.acos,
    mi_d_snp: pageRoutes.dsnp,
    mi_pace: pageRoutes.pace,
    mi_ma: pageRoutes.ma,
    mi_original: pageRoutes.medicare,
    mi_healthlink: pageRoutes.miHealthLink,
  },
  oh: {
    oh_aco: pageRoutes.acos,
    oh_pace: pageRoutes.pace,
    oh_d_snp: pageRoutes.dsnp,
    oh_ma: pageRoutes.ma,
    oh_mycare: pageRoutes.ohMycare,
  },
};
// Mapping of page pathnames from app-routing.module to kontent.ai page codenames.
export let pageCodeNames: any = {
  [languageRoutes.english]: {
    [pageRoutes.about]: 'about_us___ca',
    [pageRoutes.getHelp]: 'get_help___mcmc',
    [pageRoutes.privacy]: 'privacy_policy___ca',
    [pageRoutes.terms]: 'terms_of_use___ca',
    ca: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'california_s_care_choices___ca',
      [pageRoutes.dualEligibility]: 'dual_eligibility___ca',
      [pageRoutes.findMyCare]: 'find_my_care___get_started___ca',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___ca',
      [pageRoutes.counselorTools]: 'counselor_tools___ca',
      [pageRoutes.getHelp]: 'get_help___ca',
      [pageRoutes.glossary]: 'glossary___ca',
      [pageRoutes.learnMore]: 'learn_more___ca',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___ca',
      [pageRoutes.newToMedicare]: 'new_to_medicare___ca',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___ca',
      [pageRoutes.whatsCovered]: 'what_s_covered___ca',
    },
    il: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'illinois_s_care_choices___il',
      [pageRoutes.dualEligibility]: 'dual_eligibility___il',
      [pageRoutes.findMyCare]: 'find_care___get_started___il',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___il',
      [pageRoutes.counselorTools]: 'counselor_tools___il',
      [pageRoutes.getHelp]: 'get_help___il',
      [pageRoutes.glossary]: 'glossary___il',
      [pageRoutes.learnMore]: 'learn_more___il',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___il',
      [pageRoutes.newToMedicare]: 'new_to_medicare___il',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___il',
      [pageRoutes.whatsCovered]: 'what_s_covered___il',
    },
    mi: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'michican_s_care_choices___mi',
      [pageRoutes.dualEligibility]: 'dual_eligibility_bd56b6c',
      [pageRoutes.findMyCare]: 'find_care___get_started___mi',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___mi',
      [pageRoutes.counselorTools]: 'counselor_tools___mi',
      [pageRoutes.getHelp]: 'get_help___mi',
      [pageRoutes.glossary]: 'glossary___mi',
      [pageRoutes.learnMore]: 'learn_more___mi',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___mi',
      [pageRoutes.newToMedicare]: 'new_to_medicare___mi',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___mi',
      [pageRoutes.whatsCovered]: 'what_s_covered___mi',
    },
    oh: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'ohio_s_care_choices___oh',
      [pageRoutes.dualEligibility]: 'untitled_content_item_560c26d',
      [pageRoutes.findMyCare]: 'get_started___oh',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___oh',
      [pageRoutes.counselorTools]: 'counselor_tools___oh',
      [pageRoutes.getHelp]: 'get_help___oh',
      [pageRoutes.glossary]: 'glossary___oh',
      [pageRoutes.learnMore]: 'learn_more___oh',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___oh',
      [pageRoutes.newToMedicare]: 'new_to_medicare___oh',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___oh',
      [pageRoutes.whatsCovered]: 'what_s_covered',
    },
  },
  [languageRoutes.spanish]: {
    [pageRoutes.about]: 'about_us___ca',
    [pageRoutes.getHelp]: 'get_help___mcmc',
    [pageRoutes.privacy]: 'privacy_policy___ca',
    [pageRoutes.terms]: 'terms_of_use___ca',
    ca: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'california_s_care_choices___ca',
      [pageRoutes.dualEligibility]: 'dual_eligibility___ca',
      [pageRoutes.findMyCare]: 'find_my_care___get_started___ca',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___ca',
      [pageRoutes.counselorTools]: 'counselor_tools___ca',
      [pageRoutes.getHelp]: 'get_help___ca',
      [pageRoutes.glossary]: 'glossary___ca',
      [pageRoutes.learnMore]: 'learn_more___ca',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___ca',
      [pageRoutes.newToMedicare]: 'new_to_medicare___ca',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___ca',
      [pageRoutes.whatsCovered]: 'what_s_covered___ca',
    },
    oh: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'ohio_s_care_choices___oh',
      [pageRoutes.dualEligibility]: 'untitled_content_item_560c26d',
      [pageRoutes.findMyCare]: 'get_started___oh',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___oh',
      [pageRoutes.counselorTools]: 'counselor_tools___oh',
      [pageRoutes.getHelp]: 'get_help___oh',
      [pageRoutes.glossary]: 'glossary___oh',
      [pageRoutes.learnMore]: 'learn_more___oh',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___oh',
      [pageRoutes.newToMedicare]: 'new_to_medicare___oh',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___oh',
      [pageRoutes.whatsCovered]: 'what_s_covered',
    },
  },
  [languageRoutes.chinese]: {
    [pageRoutes.about]: 'about_us___ca',
    [pageRoutes.getHelp]: 'get_help___mcmc',
    [pageRoutes.privacy]: 'privacy_policy___ca',
    [pageRoutes.terms]: 'terms_of_use___ca',
    ca: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'california_s_care_choices___ca',
      [pageRoutes.dualEligibility]: 'dual_eligibility___ca',
      [pageRoutes.findMyCare]: 'find_my_care___get_started___ca',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___ca',
      [pageRoutes.counselorTools]: 'counselor_tools___ca',
      [pageRoutes.getHelp]: 'get_help___ca',
      [pageRoutes.glossary]: 'glossary___ca',
      [pageRoutes.learnMore]: 'learn_more___ca',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___ca',
      [pageRoutes.newToMedicare]: 'new_to_medicare___ca',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___ca',
      [pageRoutes.whatsCovered]: 'what_s_covered___ca',
    },
  },
  [languageRoutes.chineseTaiwan]: {
    [pageRoutes.about]: 'about_us___ca',
    [pageRoutes.getHelp]: 'get_help___mcmc',
    [pageRoutes.privacy]: 'privacy_policy___ca',
    [pageRoutes.terms]: 'terms_of_use___ca',
    ca: {
      [pageRoutes.about]: 'about_us___ca',
      [pageRoutes.careChoices]: 'california_s_care_choices___ca',
      [pageRoutes.dualEligibility]: 'dual_eligibility___ca',
      [pageRoutes.findMyCare]: 'find_my_care___get_started___ca',
      [pageRoutes.findTheRightCare]: 'find_the_right_care_choice___ca',
      [pageRoutes.counselorTools]: 'counselor_tools___ca',
      [pageRoutes.getHelp]: 'get_help___ca',
      [pageRoutes.glossary]: 'glossary___ca',
      [pageRoutes.learnMore]: 'learn_more___ca',
      [pageRoutes.makingTheMost]: 'making_the_most_of_your_coverage___ca',
      [pageRoutes.newToMedicare]: 'new_to_medicare___ca',
      [pageRoutes.privacy]: 'privacy_policy___ca',
      [pageRoutes.terms]: 'terms_of_use___ca',
      [pageRoutes.understandMyCare]: 'understand_my_care___ca',
      [pageRoutes.whatsCovered]: 'what_s_covered___ca',
    },
  },
};
// Mapping of kontent.ai understand my care page codenames to pathnames used in app-routing.module.
export let understandPagePathNames: any = {
  ca: {
    find_the_right_care_choice___ca: pageRoutes.findTheRightCare,
    glossary___ca: pageRoutes.glossary,
    learn_more___ca: pageRoutes.learnMore,
    making_the_most_of_your_coverage___ca: pageRoutes.makingTheMost,
    new_to_medicare___ca: pageRoutes.newToMedicare,
    what_s_covered___ca: pageRoutes.whatsCovered,
  },
  il: {
    find_the_right_care_choice___il: pageRoutes.findTheRightCare,
    glossary___il: pageRoutes.glossary,
    learn_more___il: pageRoutes.learnMore,
    making_the_most_of_your_coverage___il: pageRoutes.makingTheMost,
    new_to_medicare___il: pageRoutes.newToMedicare,
    what_s_covered___il: pageRoutes.whatsCovered,
  },
  mi: {
    find_the_right_care_choice___mi: pageRoutes.findTheRightCare,
    glossary___mi: pageRoutes.glossary,
    learn_more___mi: pageRoutes.learnMore,
    making_the_most_of_your_coverage___mi: pageRoutes.makingTheMost,
    new_to_medicare___mi: pageRoutes.newToMedicare,
    what_s_covered___mi: pageRoutes.whatsCovered,
  },
  oh: {
    find_the_right_care_choice___oh: pageRoutes.findTheRightCare,
    glossary___oh: pageRoutes.glossary,
    learn_more___oh: pageRoutes.learnMore,
    making_the_most_of_your_coverage___oh: pageRoutes.makingTheMost,
    new_to_medicare___oh: pageRoutes.newToMedicare,
    what_s_covered: pageRoutes.whatsCovered,
  },
};
// Mapping of kontent.ai page codenames to pathnames used in app-routing.module.
export let pagePathNames: any = {
  ca: {},
  il: {},
  mi: {},
  oh: {
    ohio_s_care_choices___oh: pageRoutes.careChoices,
    counselor_tools___oh: pageRoutes.counselorTools,
    get_help___oh: pageRoutes.getHelp,
    understand_my_care___oh: pageRoutes.understandMyCare,
  },
};
