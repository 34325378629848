<section class="relative">
  <button
    class="button-close dialog-close font-bold text-green-dark text-xs pt-10"
    (click)="closeDialog()"
  >
    {{ data.translatedContent[data.lang].close }}
  </button>

  <img *ngIf="data.type === 'cards'" [src]="data.src" alt="Medicare Cards" />

  <div *ngIf="data.type === 'error'">
    <p>{{ data.translatedContent[data.lang].invalidZip }}</p>
  </div>

  <div *ngIf="data.type === 'results'">
    <div class="kentico-kontent" [innerHtml]="data.body"></div>
    <div class="text-center">
      <button
        class="btn btn-ghost-green-dark mx-4"
        (click)="startQuestionnaire()"
      >
        {{ data.translatedContent[data.lang].answerQuestions }}
      </button>
      <a
        *ngIf="data.url"
        class="btn btn-green-dark mx-4 mt-6 lg:mt-0"
        (click)="closeDialog()"
        [routerLink]="['/', data.lang, data.state, data.url]"
      >
        {{ data.translatedContent[data.lang].showNow }}
      </a>
      <button
        *ngIf="!data.url"
        class="btn btn-green-dark mx-4 mt-6 lg:mt-0"
        (click)="dontStartQuestionnaire()"
      >
        {{
          data.state === "ca" ? "Medicare + Medi-Cal" : "Medicare + Medicaid"
        }}
      </button>
    </div>
  </div>

  <div *ngIf="data.type === 'wrongState'">
    <p>{{ data.translatedContent[data.lang].wrongState[data.state] }}</p>
  </div>
</section>
