export let glossaryLinks = [
  {
    enabled: false,
    value: 'a',
  },
  {
    enabled: false,
    value: 'b',
  },
  {
    enabled: false,
    value: 'c',
  },
  {
    enabled: false,
    value: 'd',
  },
  {
    enabled: false,
    value: 'e',
  },
  {
    enabled: false,
    value: 'f',
  },
  {
    enabled: false,
    value: 'g',
  },
  {
    enabled: false,
    value: 'h',
  },
  {
    enabled: false,
    value: 'i',
  },
  {
    enabled: false,
    value: 'j',
  },
  {
    enabled: false,
    value: 'k',
  },
  {
    enabled: false,
    value: 'l',
  },
  {
    enabled: false,
    value: 'm',
  },
  {
    enabled: false,
    value: 'n',
  },
  {
    enabled: false,
    value: 'o',
  },
  {
    enabled: false,
    value: 'p',
  },
  {
    enabled: false,
    value: 'q',
  },
  {
    enabled: false,
    value: 'r',
  },
  {
    enabled: false,
    value: 's',
  },
  {
    enabled: false,
    value: 't',
  },
  {
    enabled: false,
    value: 'u',
  },
  {
    enabled: false,
    value: 'v',
  },
  {
    enabled: false,
    value: 'w',
  },
  {
    enabled: false,
    value: 'x',
  },
  {
    enabled: false,
    value: 'y',
  },
  {
    enabled: false,
    value: 'z',
  },
];
