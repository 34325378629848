<div class="relative">
  <div class="flex justify-between ncoa-container-padding lg:py-8">
    <div class="flex items-center">
      <a
        class="w-56 md:w-72 lg:w-96"
        [routerLink]="languageRoutes.english"
        *ngIf="!state"
      >
        <img src="/assets/NCOA_Logo_MCMC.svg" alt="My Care My Choice Logo" />
      </a>
      <a
        class="w-56 md:w-72"
        [routerLink]="['/', lang, 'ca']"
        *ngIf="state === 'ca'"
      >
        <img src="/assets/NCOA_Logo_California.svg" alt="My Care My Choice California Logo" />
      </a>
      <a
        class="w-56 md:w-72"
        [routerLink]="['/', lang, 'il']"
        *ngIf="state === 'il'"
      >
        <img src="/assets/NCOA_Logo_Illinois.svg" alt="My Care My Choice Illinois Logo" />
      </a>
      <a
        class="w-56 md:w-72"
        [routerLink]="['/', lang, 'mi']"
        *ngIf="state === 'mi'"
      >
        <img src="/assets/NCOA_Logo_Michigan.svg" alt="My Care My Choice Michigan Logo" />
      </a>
      <a
        class="w-56 md:w-72"
        [routerLink]="['/', lang, 'oh']"
        *ngIf="state === 'oh'"
      >
        <img src="/assets/NCOA_Logo_Ohio.svg" alt="My Care My Choice Ohio Logo" />
      </a>
    </div>

    <div>
      <div class="flex items-center justify-end">
        <nav class="hidden invisible lg:block lg:visible" aria-label="Secondary menu">
          <ul class="flex text-xs">
            <li *ngIf="state && lang" class="mr-16">
              <a
                class="px-4 py-2 rounded-xl hover:bg-green hover:text-white"
                [routerLink]="['/', lang, state, pageRoutes.counselorTools]"
                routerLinkActive="active"
                >{{ translatedContent[lang].counselorTools }}</a
              >
            </li>
            <li
              [ngClass]="state ? 'mr-16' : ''"
            >
              <a
                *ngIf="!state && lang"
                class="px-4 py-2 rounded-xl hover:bg-green hover:text-white"
                [routerLink]="['/', lang, pageRoutes.getHelp]"
                routerLinkActive="active"
                >{{ translatedContent[lang].getHelp }}</a
              >
              <a
                *ngIf="state && lang"
                class="px-4 py-2 rounded-xl hover:bg-green hover:text-white"
                [routerLink]="['/', lang, state, pageRoutes.getHelp]"
                routerLinkActive="active"
                >{{ translatedContent[lang].getHelp }}</a
              >
            </li>
            <li
              *ngIf="state"
              [ngClass]="state === 'ca' || state === 'oh' ? 'mr-16' : ''"
            >
              <button
                class="search-link hover:underline"
                (click)="toggleSearch()"
              >
                {{ translatedContent[lang].search }}
              </button>
            </li>
          </ul>
        </nav>

        <mat-form-field
          *ngIf="state && ( state === 'ca' || state === 'oh' )"
          class="language-dropdown hidden invisible sm:block sm:visible"
          appearance="fill"
        >
          <mat-select [value]="lang">
            <mat-option
              *ngFor="let language of languages"
              [value]="language.value"
              (click)="selectLang(language.value)"
              >{{ language.viewValue }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <button
          *ngIf="state && lang"
          class="
            search-link-mobile
            items-end
            h-16
            hidden
            invisible
            sm:flex sm:visible
            lg:hidden lg:invisible
          "
          (click)="toggleSearch()"
        >
          {{ translatedContent[lang].search }}
        </button>

        <mat-button-toggle
          #menuToggle
          aria-labelledby="Open Menu"
          class="lg:hidden lg:invisible"
          *ngIf="lang"
          [ngClass]="menuToggle.checked ? 'bg-gray-lightest' : ''"
          (click)="onMobileDropdownClick()"
          disableRipple
          ><i
            class="text-3xl text-green-dark menu-toggle-icon"
            [ngClass]="menuToggle.checked ? 'fas fa-times' : 'fas fa-bars'"
          ></i>
          {{
            menuToggle.checked
              ? translatedContent[lang].close
              : translatedContent[lang].menu
          }}</mat-button-toggle
        >
      </div>

      <nav *ngIf="state" aria-label="Main menu">
        <ul
          class="
            items-center
            justify-end
            font-bold
            mt-8
            hidden
            invisible
            lg:flex lg:visible
          "
        >
          <li *ngIf="!linkLabelsLoaded" class="w-60 mr-8">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '1.5rem',
                margin: '0'
              }"
            ></ngx-skeleton-loader>
          </li>
          <li *ngIf="linkLabelsLoaded">
            <a
              class="
                px-4
                py-2
                rounded-2xl
                inline-block
                hover:text-black hover:bg-gray-lightest
              "
              [routerLink]="['/', lang, state, pageRoutes.findMyCare]"
              routerLinkActive="active"
              >{{ getStartedLabel }}</a
            >
          </li>

          <li *ngIf="!understandMenuItemsLoaded" class="w-60 mr-8">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '1.5rem',
                margin: '0'
              }"
            ></ngx-skeleton-loader>
          </li>
          <li
            *ngIf="understandMenuItemsLoaded"
            class="relative"
            (mouseleave)="hideDropdown($event)"
            (mouseover)="showDropdown($event)"
            (keydown)="onKeyDown($event)"
          >
            <a
              class="px-4 py-2 inline-block nav-dropdown-link js-menu-trigger"
              [routerLink]="['/', lang, state, pageRoutes.understandMyCare]"
              routerLinkActive="active"
            >
              {{ understandMyCareLabel }}
            </a>
            <ul
              class="
                state-dropdown-menu
                rounded-2xl
                overflow-auto
                absolute
                z-10
              "
            >
              <li
                *ngFor="let item of understandMenuItems"
                class="bg-gray-lightest"
              >
                <a
                  class="inline-block p-4 w-96"
                  [routerLink]="item.componentLink"
                  routerLinkActive="active"
                  >{{ item.value }}</a
                >
              </li>
            </ul>
          </li>

          <li *ngIf="!careChoiceMenuItemsLoaded" class="w-60">
            <ngx-skeleton-loader
              count="1"
              [theme]="{
                height: '1.5rem',
                margin: '0'
              }"
            ></ngx-skeleton-loader>
          </li>
          <li
            *ngIf="careChoiceMenuItemsLoaded"
            class="relative"
            (mouseleave)="hideDropdown($event)"
            (mouseover)="showDropdown($event)"
            (keydown)="onKeyDown($event)"
          >
            <a
              class="px-4 py-2 inline-block nav-dropdown-link js-menu-trigger"
              [routerLink]="['/', lang, state, pageRoutes.careChoices]"
              routerLinkActive="active"
            >
              {{ careChoicesLabel }}
            </a>
            <ul
              class="
                state-dropdown-menu
                rounded-2xl
                overflow-auto
                absolute
                z-10
              "
            >
              <li
                *ngFor="let item of careChoiceMenuItems"
                class="bg-gray-lightest"
              >
                <a
                  class="inline-block p-4 w-96"
                  [routerLink]="item.componentLink"
                  routerLinkActive="active"
                  >{{ item.value }}</a
                >
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </div>
  </div>

  <!-- Mobile Nav -->
  <nav class="absolute z-50 lg:hidden lg:invisible" aria-label="Mobile menu">
    <mat-expansion-panel #mainPanel>
      <div>
        <ul class="bg-gray-lightest font-bold">
          <li *ngIf="state" class="border-b-2 border-white">
            <a
              class="py-6 px-8 inline-block w-full"
              [routerLink]="['/', lang, state, pageRoutes.findMyCare]"
              routerLinkActive="active"
              (click)="onMobileMenuItemClick()"
              >{{ getStartedLabel }}</a
            >
          </li>
        </ul>
      </div>

      <!-- Understand My Care Submenu -->
      <mat-expansion-panel
        #understandPanel
        *ngIf="state"
        class="bg-gray-lightest"
      >
        <mat-expansion-panel-header class="border-b-2 border-white">
          <mat-panel-title class="font-bold">
            <p class="py-6 px-8">{{ understandMyCareLabel }}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <ul class="bg-white">
            <li *ngFor="let item of understandMenuItems">
              <a
                class="
                  px-8
                  py-4
                  inline-block
                  w-full
                  border-b-2 border-gray-lightest
                "
                [routerLink]="item.componentLink"
                routerLinkActive="active"
                (click)="onMobileMenuItemClick()"
                >{{ item.value }}</a
              >
            </li>
          </ul>
        </div>
      </mat-expansion-panel>

      <!-- Care Choices Submenu -->
      <mat-expansion-panel
        #careChoicesPanel
        *ngIf="state"
        class="bg-gray-lightest"
      >
        <mat-expansion-panel-header class="border-b-2 border-white">
          <mat-panel-title class="font-bold">
            <p class="py-6 px-8">{{ careChoicesLabel }}</p>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div>
          <ul class="bg-white">
            <li *ngFor="let item of careChoiceMenuItems">
              <a
                class="
                  px-8
                  py-4
                  inline-block
                  w-full
                  border-b-2 border-gray-lightest
                "
                [routerLink]="item.componentLink"
                routerLinkActive="active"
                (click)="onMobileMenuItemClick()"
                >{{ item.value }}</a
              >
            </li>
          </ul>
        </div>
      </mat-expansion-panel>

      <div>
        <ul class="bg-gray-lightest">
          <li *ngIf="state && lang" class="border-b-2 border-white">
            <a
              class="py-6 px-8 inline-block w-full"
              [routerLink]="['/', lang, state, pageRoutes.counselorTools]"
              routerLinkActive="active"
              (click)="onMobileMenuItemClick()"
              >{{ translatedContent[lang].counselorTools }}</a
            >
          </li>
          <li *ngIf="!state && lang" class="border-b-2 border-white">
            <a
              class="py-6 px-8 inline-block w-full"
              [routerLink]="['/', lang, pageRoutes.getHelp]"
              routerLinkActive="active"
              (click)="onMobileMenuItemClick()"
              >{{ translatedContent[lang].getHelp }}</a
            >
          </li>
          <li *ngIf="state && lang" class="border-b-2 border-white">
            <a
              class="py-6 px-8 inline-block w-full"
              [routerLink]="['/', lang, state, pageRoutes.getHelp]"
              routerLinkActive="active"
              (click)="onMobileMenuItemClick()"
              >{{ translatedContent[lang].getHelp }}</a
            >
          </li>
          <li
            *ngIf="!state && lang"
            class="border-b-2 border-white md:border-none"
          >
            <a
              class="py-6 px-8 inline-block w-full"
              [routerLink]="['/', lang, pageRoutes.about]"
              routerLinkActive="active"
              (click)="onMobileMenuItemClick()"
              >{{ translatedContent[lang].about }}</a
            >
          </li>
          <li
            *ngIf="state && lang"
            class="border-b-2 border-white md:border-none"
          >
            <a
              class="py-6 px-8 inline-block w-full"
              [routerLink]="['/', lang, state, pageRoutes.about]"
              routerLinkActive="active"
              (click)="onMobileMenuItemClick()"
              >{{ translatedContent[lang].about }}</a
            >
          </li>
          <li
            *ngIf="state && ( state === 'ca' || state === 'oh' )"
            class="py-6 px-8 inline-block w-full md:hidden md:invisible"
          >
            <mat-form-field class="language-dropdown w-full" appearance="fill">
              <mat-select [value]="lang">
                <mat-option
                  *ngFor="let language of languages"
                  [value]="language.value"
                  (click)="selectLang(language.value); onMobileMenuItemClick()"
                  >{{ language.viewValue }}</mat-option
                >
              </mat-select>
            </mat-form-field>
          </li>
        </ul>
      </div>
    </mat-expansion-panel>
  </nav>
</div>
