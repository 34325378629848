import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { FindMyCareComponent } from './find-my-care/find-my-care.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { CareModelComponent } from './care-model/care-model.component';
import { MyCareResultsComponent } from './my-care-results/my-care-results.component';
import { QuestionComponent } from './shared/components/question/question.component';
import { UnderstandMyCareComponent } from './understand-my-care/understand-my-care.component';
import { CareChoicesComponent } from './care-choices/care-choices.component';
import { ParentComponent } from './parent/parent.component';
import { BasicPageComponent } from './basic-page/basic-page.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { languageRoutes, pageRoutes } from './shared/data/routes-and-codenames';

/**
 * All routes in the app that need to be statically generated for SEO
 * purposes need to be manually added to scully.mcmc-web.config.ts in the
 * extraRoutes config property. They will not be automatically generated
 * from this file.
 */

// Options to enable hash links (#) on the Glossary page.
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

// Child routes for every language.
const globalChildren: Routes = [
  { path: pageRoutes.about, component: BasicPageComponent },
  { path: pageRoutes.counselorTools, component: BasicPageComponent },
  { path: pageRoutes.privacy, component: BasicPageComponent },
  { path: pageRoutes.terms, component: BasicPageComponent },
];

// Child routes for understand my care.
const understandChildren: Routes = [
  { path: pageRoutes.findTheRightCare, component: BasicPageComponent },
  { path: pageRoutes.glossary, component: GlossaryComponent },
  { path: pageRoutes.learnMore, component: BasicPageComponent },
  { path: pageRoutes.makingTheMost, component: BasicPageComponent },
  { path: pageRoutes.newToMedicare, component: BasicPageComponent },
  { path: pageRoutes.whatsCovered, component: BasicPageComponent },
];

// Child routes for all states.
const stateChildren: Routes = [
  ...globalChildren,
  { path: pageRoutes.acos, component: CareModelComponent },
  { path: `${pageRoutes.acos}/:path`, component: CareModelComponent },
  { path: pageRoutes.careChoices, component: CareChoicesComponent },
  { path: pageRoutes.dsnp, component: CareModelComponent },
  { path: `${pageRoutes.dsnp}/:path`, component: CareModelComponent },
  { path: pageRoutes.dualEligibility, component: BasicPageComponent },
  { path: pageRoutes.fidesnp, component: CareModelComponent },
  { path: `${pageRoutes.fidesnp}/:path`, component: CareModelComponent },
  { path: pageRoutes.findMyCare, component: FindMyCareComponent },
  { path: `${pageRoutes.findMyCare}/:path`, component: QuestionComponent },
  { path: pageRoutes.getHelp, component: BasicPageComponent },
  { path: pageRoutes.ma, component: CareModelComponent },
  { path: `${pageRoutes.ma}/:path`, component: CareModelComponent },
  { path: pageRoutes.ohMycare, component: CareModelComponent },
  { path: `${pageRoutes.ohMycare}/:path`, component: CareModelComponent },
  { path: pageRoutes.pace, component: CareModelComponent },
  { path: `${pageRoutes.pace}/:path`, component: CareModelComponent },
  { path: pageRoutes.results, component: MyCareResultsComponent },
  { path: pageRoutes.search, component: SearchResultsComponent },
  {
    path: pageRoutes.understandMyCare,
    component: UnderstandMyCareComponent,
    children: understandChildren,
  },
];

// California routes.
const ca: Routes = [
  {
    path: 'ca',
    component: ParentComponent,
    children: [
      ...stateChildren,
      { path: pageRoutes.calMediconnect, component: CareModelComponent },
    ],
  },
];

// Illinois routes.
const il: Routes = [
  {
    path: 'il',
    component: ParentComponent,
    children: [
      ...stateChildren,
      { path: pageRoutes.healthchoice, component: CareModelComponent },
      { path: pageRoutes.medimedi, component: CareModelComponent },
      { path: pageRoutes.mltssProgram, component: CareModelComponent },
    ]
  },
];

// Michigan routes.
const mi: Routes = [
  {
    path: 'mi',
    component: ParentComponent,
    children: [
      ...stateChildren,
      { path: pageRoutes.medicare, component: CareModelComponent },
      { path: pageRoutes.miHealthLink, component: CareModelComponent },
    ],
  },
];

// Ohio routes.
const oh: Routes = [
  { path: 'oh', component: ParentComponent, children: stateChildren },
];

// Combined routes for the entire app.
const routes: Routes = [
  {
    path: languageRoutes.english,
    component: ParentComponent,
    children: [
      ...globalChildren,
      ...ca,
      ...il,
      ...mi,
      ...oh,
      { path: pageRoutes.getHelp, component: BasicPageComponent },
      { path: pageRoutes.search, component: SearchResultsComponent },
    ],
  },
  {
    path: languageRoutes.spanish,
    component: ParentComponent,
    children: [
      ...globalChildren,
      ...ca,
      ...oh,
      { path: pageRoutes.getHelp, component: BasicPageComponent },
      { path: pageRoutes.search, component: SearchResultsComponent },
    ],
  },
  {
    path: languageRoutes.chinese,
    component: ParentComponent,
    children: [
      ...globalChildren,
      ...ca,
      { path: pageRoutes.getHelp, component: BasicPageComponent },
      { path: pageRoutes.search, component: SearchResultsComponent },
    ],
  },
  {
    path: languageRoutes.chineseTaiwan,
    component: ParentComponent,
    children: [
      ...globalChildren,
      ...ca,
      { path: pageRoutes.getHelp, component: BasicPageComponent },
      { path: pageRoutes.search, component: SearchResultsComponent },
    ],
  },
  { path: '**', redirectTo: languageRoutes.english },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
