<section *ngIf="!activatedRoute.children.length" class="ncoa-container-padding">
  <div *ngIf="!page" class="kentico-kontent my-4 md:my-8">
    <ngx-skeleton-loader
      class="flex justify-center"
      [theme]="{
        height: '3.125rem',
        width: '50%'
      }"
    ></ngx-skeleton-loader>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="2"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
  <div *ngIf="page" class="kentico-kontent">
    <h1
      [innerHtml]="page.title.value"
      class="font-serif text-center text-2xl mt-4 md:mt-8 lg:text-4xl"
    ></h1>
    <div
      [innerHtml]="page.introduction.value"
      class="text-center mt-4 md:mt-8"
    ></div>
    <div [innerHtml]="page.body.value" class="mt-4 md:mt-8"></div>
  </div>

  <div
    class="
      bg-extended
      rounded-tl-4rem
      relative
      bg-gray-lighter
      px-6
      md:px-10
      lg:px-24
    "
  >
    <div *ngIf="!understandMenuItems.length">
      <div class="pb-12">
        <ngx-skeleton-loader
          count="6"
          [theme]="{
            height: '4rem',
            margin: '4rem 0 0 0',
            width: '100%'
          }"
        ></ngx-skeleton-loader>
      </div>
    </div>
    <div *ngIf="understandMenuItems.length">
      <ul class="pt-4 pb-6 lg:pb-10">
        <li class="understand-item" *ngFor="let item of understandMenuItems">
          <a
            class="py-6 lg:py-8 lg:flex lg:justify-between"
            [routerLink]="item.componentLink"
          >
            <div class="lg:w-3/4">
              <h2 class="link mb-2 font-serif text-xl lg:text-2xl">
                {{ item.value }}
              </h2>
              <div class="mb-2 lg:mb-0" [innerHtml]="item.summary"></div>
            </div>
            <div class="lg:flex items-end justify-end lg:w-1/4">
              <p class="link link-with-arrow text-sm font-bold mr-6 text-right">
                {{ translatedContent[lang].learnMore }}
              </p>
            </div>
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>

<router-outlet></router-outlet>
