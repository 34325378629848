import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { County } from '../../models/questions.model';

@Injectable({
  providedIn: 'root',
})
export class ZipCodeFormService {
  private readonly baseUrl = environment.ncoaApiBaseUrl;
  private _counties: County[] = [];
  private readonly _requestDelay = 500;
  private readonly _zipCodeLength = 5;

  constructor(private http: HttpClient) {}

  get counties(): County[] {
    return this._counties;
  }

  setCounties(counties: County[]) {
    this._counties = counties;
  }

  getCountiesByZip(zipCode: any) {
    return this.http.get(`${this.baseUrl}/counties?zip_code=${zipCode}`);
  }

  get requestDelay(): number {
    return this._requestDelay;
  }

  getPlans(modelId: string | null, zipCode: number, county: string) {
    return modelId
      ? this.http.get(
          `${this.baseUrl}/plans?model_id=${modelId}&zip_code=${zipCode}&county=${county}`
        )
      : this.http.get(
          `${this.baseUrl}/plans?zip_code=${zipCode}&county=${county}`
        );
  }

  get zipCodeLength(): number {
    return this._zipCodeLength;
  }
}
