<section class="relative">
  <button
    class="button-close dialog-close font-bold text-green-dark text-xs pt-10"
    (click)="closeDialog()"
  >
    {{ data.translatedContent[data.lang].close }}
  </button>
  <h2 class="font-serif text-2xl">
    {{ data.translatedContent[data.lang].whyAreWeAsking }}
  </h2>
  <div class="kentico-kontent mt-6" [innerHtml]="data.body"></div>
</section>
