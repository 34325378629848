import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuestionsService } from '../services/questions/questions.service';
import { ZipCodeFormService } from '../services/zipcode-form/zipcode-form.service';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { County, Age } from '../models/questions.model';
import { ContentService } from '../services/content/content.service';
import { UtilityService } from '../services/utility/utility.service';
import { Block, Page } from '../models/content.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/components/dialog/dialog.component';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { Subscription } from 'rxjs';
import { find } from 'lodash';
import { pageRoutes } from '../shared/data/routes-and-codenames';
import { translatedContent } from '../shared/data/translated-content';

@Component({
  selector: 'app-find-my-care',
  templateUrl: './find-my-care.component.html',
  styleUrls: ['./find-my-care.component.scss'],
})
export class FindMyCareComponent implements OnInit, OnDestroy {
  ages!: Age[];
  cards?: Block;
  counties: County[] = [];
  getBlocksSubscription!: Subscription;
  getCountiesSubscription!: Subscription;
  getPageSubscription!: Subscription;
  getPlansSubscription!: Subscription;
  getQuestionsSubscription!: Subscription;
  hasFormSubmitted = false;
  href!: string;
  isFormValid!: boolean;
  lang!: string;
  page!: Page;
  state!: string;
  firstQuestionSlug!: string;
  hasSeenResults!: boolean;
  translatedContent: any;
  zipCodeForm!: FormGroup;

  constructor(
    private contentService: ContentService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private questionsService: QuestionsService,
    private routeHelperService: RouteHelperService,
    private router: Router,
    private utilityService: UtilityService,
    private zipCodeService: ZipCodeFormService
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    this.ages = this.utilityService.ages;
    this.hasSeenResults = this.questionsService.hasSeenResults;
    this.counties = this.zipCodeService.counties;

    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.href = this.routeHelperService.getFinalPathValue();

    if (!this.questionsService.getFirstQuestionSlug(this.lang, this.state)) {
      this.questionsService.setState(this.state);
      this.getQuestions();
    } else {
      this.firstQuestionSlug = this.questionsService.getFirstQuestionSlug(
        this.lang,
        this.state
      );
    }

    this.setupForm();

    if (this.zipCodeService.counties) {
      this.counties = this.zipCodeService.counties;
    }

    this.getBlockContent();
    this.getPageContent();
  }

  getBlockContent(): void {
    this.getBlocksSubscription = this.contentService
      .getBlocks(this.lang)
      .subscribe(
        (data: { items: Block[] }) => {
          try {
            this.cards = data.items.find(
              (element) =>
                element.system.codename === `get_started_cards___${this.state}`
            );
          } catch {
            console.error('There was a problem fetching blocks.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getQuestions(): void {
    this.getQuestionsSubscription = this.contentService
      .getQuestions(this.lang, this.state)
      .subscribe(
        (res) => {
          try {
            this.questionsService.setQuestions(this.lang, res.items);
            this.firstQuestionSlug = this.questionsService.getFirstQuestionSlug(
              this.lang,
              this.state
            );
          } catch {
            console.error('There was a problem fetching questions.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getPageContent(): void {
    const pageCodeName = this.routeHelperService.getCodeNameByState(
      this.lang,
      this.state,
      this.href
    );

    if (pageCodeName) {
      this.getPageSubscription = this.contentService
        .getPages(this.lang, this.state)
        .subscribe(
          (res: any) => {
            try {
              this.page = find(res.items, ['system.codename', pageCodeName]);
            } catch {
              console.error('There was a problem fetching page.');
            }
          },
          (e) => {
            console.error(e);
          }
        );
    }
  }

  openCardsDialog(): void {
    this.dialog.open(DialogComponent, {
      data: {
        lang: this.lang,
        src: this.cards?.image.value[0].url,
        type: 'cards',
        translatedContent: this.translatedContent,
      },
    });
  }

  openErrorDialog(message: string): void {
    this.dialog.open(DialogComponent, {
      data: {
        lang: this.lang,
        message,
        type: 'error',
        state: this.state,
        translatedContent: this.translatedContent,
      },
    });
  }

  openResultsDialog(numberOfPlans: number, planCodename: string): void {
    if (this.getBlocksSubscription) this.getBlocksSubscription.unsubscribe();
    const blockCodename = numberOfPlans
      ? `get_started_one_result_overlay___${this.state}`
      : `no_plans_available`;
    const planUrl =
      numberOfPlans === 1
        ? this.routeHelperService.getCareModelUrlByCodeName(
            planCodename,
            this.state
          )
        : '';

    this.getBlocksSubscription = this.contentService
      .getBlocks(this.lang)
      .subscribe(
        (data: { items: Block[] }) => {
          try {
            let block = data.items.find(
              (element) => element.system.codename === blockCodename
            );

            this.dialog.open(DialogComponent, {
              data: {
                body: block?.content.value,
                lang: this.lang,
                dontStartQuestionnaire: this.dontStartQuestionnaire,
                startQuestionnaire: this.startQuestionnaire,
                state: this.state,
                type: 'results',
                url: planUrl,
                translatedContent: this.translatedContent,
              },
            });
          } catch {
            console.error('There was a problem fetching blocks.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  openWrongStateDialog(): void {
    this.dialog.open(DialogComponent, {
      data: {
        lang: this.lang,
        type: 'wrongState',
        state: this.state,
        translatedContent: this.translatedContent,
      },
    });
  }

  setupForm(): void {
    this.zipCodeForm = this.fb.group({
      age: [
        this.questionsService.age ? this.questionsService.age : null,
        Validators.required,
      ],
      county: [
        this.questionsService.county ? this.questionsService.county : null,
        Validators.required,
      ],
      zipCode: [
        this.questionsService.zipCode ? this.questionsService.zipCode : null,
        [
          // TODO: Add number validator to zipCode
          Validators.required,
          Validators.minLength(this.zipCodeService.zipCodeLength),
          Validators.maxLength(this.zipCodeService.zipCodeLength),
        ],
      ],
      medicare: [this.questionsService.medicare, Validators.required],
    });

    this.zipCodeForm
      .get('zipCode')
      ?.valueChanges.pipe(debounceTime(this.zipCodeService.requestDelay))
      .subscribe((zipCode) => {
        const isZipCodeValid = this.zipCodeForm.controls.zipCode.valid;

        if (isZipCodeValid) {
          if (this.getCountiesSubscription)
            this.getCountiesSubscription.unsubscribe();
          this.questionsService.setZipCode(zipCode);

          this.getCountiesSubscription = this.zipCodeService
            .getCountiesByZip(zipCode)
            .subscribe(
              (res: any) => {
                try {
                  if (
                    res.data[0].attributes.state_code.toLowerCase() !==
                    this.state
                  ) {
                    this.counties = [];
                    this.zipCodeForm.controls.county.setValue(null);
                    this.questionsService.setZipCode(zipCode);
                    this.questionsService.setCounty('');
                    this.openWrongStateDialog();
                  } else {
                    let counties = res.data.map((county: any) => {
                      return {
                        id: county.id,
                        name: county.attributes.name,
                      };
                    });

                    this.counties = counties;
                    this.zipCodeService.setCounties(this.counties);
                    this.zipCodeForm.get('county')?.setValue(counties[0].name);
                  }
                } catch {
                  console.error('There was a problem fetching counties.');
                }
              },

              (e) => {
                this.counties = [];
                this.zipCodeForm.controls.county.setValue(null);
                this.questionsService.setZipCode(zipCode);
                this.questionsService.setCounty('');
                this.openErrorDialog(e.error);
              }
            );
        } else {
          this.questionsService.setZipCode('');
        }
      });

    this.zipCodeForm.get('county')?.valueChanges.subscribe((county: string) => {
      this.questionsService.setCounty(county);
    });

    this.zipCodeForm.get('age')?.valueChanges.subscribe((age) => {
      this.questionsService.setAge(age);
    });

    this.zipCodeForm.get('medicare')?.valueChanges.subscribe((medicare) => {
      this.questionsService.setMedicare(medicare);
    });

    this.zipCodeForm.valueChanges.subscribe(() => {
      this.isFormValid = this.zipCodeForm.valid;
    });
  }

  onSubmit(): void {
    this.hasFormSubmitted = true;
    this.isFormValid = this.zipCodeForm.valid;
    const hasMedicare = this.zipCodeForm.get('medicare')?.value;

    if (hasMedicare === 'no') {
      this.router.navigate([
        '/',
        this.lang,
        this.state,
        pageRoutes.dualEligibility,
      ]);
      return;
    }

    if (this.isFormValid) {
      if (this.getPlansSubscription) this.getPlansSubscription.unsubscribe();
      const zipCode = this.zipCodeForm.get('zipCode')?.value;
      const county = this.zipCodeForm.get('county')?.value;

      this.getPlansSubscription = this.zipCodeService
        .getPlans(null, zipCode, county)
        .subscribe(
          (res: any) => {
            try {
              const numberOfPlans = res.meta.count;
              const planCodename =
                numberOfPlans === 1 ? res.data[0].attributes.model_id : '';
              if (numberOfPlans > 1) this.startQuestionnaire();
              if (numberOfPlans <= 1)
                this.openResultsDialog(numberOfPlans, planCodename);
            } catch {
              console.error('There was a problem fetching plans.');
            }
          },
          (e) => {
            console.error(e.error);
          }
        );
    }
  }

  resetQuestionnaire(): void {
    this.questionsService.resetAnswers();
    this.setupForm();
    this.hasSeenResults = false;
  }

  startQuestionnaire = (): void => {
    this.router.navigate([
      '/',
      this.lang,
      this.state,
      pageRoutes.findMyCare,
      this.firstQuestionSlug,
    ]);
  };

  dontStartQuestionnaire = (): void => {
    this.router.navigate([
      '/',
      this.lang,
      this.state,
      pageRoutes.understandMyCare,
      pageRoutes.makingTheMost,
    ]);
  };

  ngOnDestroy(): void {
    if (this.getBlocksSubscription) this.getBlocksSubscription.unsubscribe();
    if (this.getCountiesSubscription)
      this.getCountiesSubscription.unsubscribe();
    if (this.getPageSubscription) this.getPageSubscription.unsubscribe();
    if (this.getPlansSubscription) this.getPlansSubscription.unsubscribe();
    if (this.getQuestionsSubscription)
      this.getQuestionsSubscription.unsubscribe();
  }
}
