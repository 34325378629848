<div *ngIf="page" class="ncoa-container md:flex">
  <app-side-nav></app-side-nav>
  <div class="kentico-kontent max-w-4xl px-4 md:px-8">
    <h1
      [innerHtml]="page.title.value"
      class="font-serif text-center text-3xl md:text-4xl"
    ></h1>
    <div [innerHtml]="page.introduction.value" class="mt-4 md:mt-8"></div>
    <div [innerHtml]="page.body.value" class="mt-4 md:mt-8"></div>

    <div *ngIf="glossaryItems">
      <ul class="glossary-links p-0">
        <li *ngFor="let link of glossaryLinks" class="list-none inline-block">
          <a
            *ngIf="link.enabled"
            routerLink="./"
            fragment="{{ link.value }}"
            class="block px-4 py-2"
            >{{ link.value.toUpperCase() }}</a
          >
          <span *ngIf="!link.enabled" class="text-gray px-4 py-2">{{
            link.value.toUpperCase()
          }}</span>
        </li>
      </ul>
      <div *ngFor="let item of glossaryItems; let i = index">
        <h2
          *ngIf="isFirstItemOfLetter(item, i)"
          id="{{ item.term.value.toLowerCase()[0] }}"
          class="text-xl"
        >
          {{ item.term.value }}
        </h2>
        <h2 *ngIf="!isFirstItemOfLetter(item, i)" class="text-xl">
          {{ item.term.value }}
        </h2>
        <div [innerHtml]="item.definition.value"></div>
      </div>
    </div>
  </div>
</div>
