import { Component, DoCheck, OnInit } from '@angular/core';
import { SearchService } from '../services/search/search.service';
import { CareModel, Page } from 'src/app/models/content.model';
import { UtilityService } from '../services/utility/utility.service';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { Router } from '@angular/router';
import { translatedContent } from '../shared/data/translated-content';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss'],
})
export class SearchResultsComponent implements OnInit, DoCheck {
  lang!: string;
  searchResultCareModels!: CareModel[];
  searchResultPages!: Page[];
  searchTerm!: string;
  state!: string;
  translatedContent: any;

  constructor(
    private routeHelperService: RouteHelperService,
    private router: Router,
    private searchService: SearchService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;

    this.setupPage();
  }

  ngDoCheck(): void {
    this.setupPage();
  }

  addSearchResultLinks(): void {
    if (this.searchResultCareModels) {
      this.searchResultCareModels.forEach((careModel) => {
        const componentLinkString =
          this.routeHelperService.getCareModelUrlByCodeName(
            careModel.system.codename,
            this.state
          );

        if (componentLinkString) {
          careModel.url = this.utilityService.createCareModelLink(
            careModel.system.codename,
            this.lang,
            this.state
          );
        }
      });
    }

    if (this.searchResultPages) {
      this.searchResultPages.forEach((page) => {
        const componentLinkString =
          this.routeHelperService.getPageUrlByCodeName(
            page.system.codename,
            this.state
          );

        if (componentLinkString) {
          page.url = this.utilityService.createPageLink(
            page.system.codename,
            this.lang,
            this.state
          );
        }
      });
    }
  }

  setupPage(): void {
    let { searchResultCareModels, searchResultPages, searchTerm } =
      this.searchService.getSearchResults();

    if (!searchTerm) {
      this.state
        ? this.router.navigate(['/', this.lang, this.state])
        : this.router.navigate(['/', this.lang]);
    }

    this.searchResultCareModels = searchResultCareModels;
    this.searchResultPages = searchResultPages;
    this.searchTerm = searchTerm;

    this.addSearchResultLinks();
  }
}
