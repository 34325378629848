<section *ngIf="!activatedRoute.children.length">
  <div
    [ngClass]="state ? 'flex-wrap-reverse' : 'flex-wrap'"
    class="flex ncoa-container"
  >
    <div class="w-full md:w-1/2 md:pl-8">
      <div
        *ngIf="!blockContentLoaded && !state"
        class="md:bg-gray-lighter h-60 md:h-full"
      >
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            'border-radius': '0',
            'border-top-right-radius': '20rem',
            width: '100%',
            height: '100%',
            margin: '0'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div *ngIf="!blockContentLoaded && state" class="h-60 md:h-full">
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            'border-radius': '0',
            'border-bottom-left-radius': '10rem',
            width: '100%',
            height: '100%',
            margin: '0'
          }"
        >
        </ngx-skeleton-loader>
      </div>
      <div
        *ngIf="headerImg && blockContentLoaded"
        class="h-full"
        [class.md:bg-gray-lighter]="!state"
      >
        <img
          *ngIf="headerImg.image.value.length"
          class="w-full h-full object-cover relative"
          [ngClass]="!state ? 'parent-hero-img' : 'hero-img'"
          src="{{ headerImg.image.value[0].url }}"
          alt="{{ headerImg.title.value }}"
        />
      </div>
    </div>

    <div
      class="
        hero-headline-container
        relative
        bg-gray-lighter
        px-4
        py-8
        w-full
        md:w-1/2 md:px-8 md:pt-12 md:pb-16
      "
    >
      <div *ngIf="!blockContentLoaded">
        <ngx-skeleton-loader
          count="3"
          [theme]="{
            height: '3.125rem'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          count="3"
          [theme]="{
            margin: '1rem 0 0 0'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div
        *ngIf="heading && blockContentLoaded"
        [innerHtml]="heading.content.value"
      ></div>
      <div class="text-center md:text-left">
        <a
          *ngIf="state && lang"
          class="btn btn-green-dark inline-block mt-8 md:mt-10"
          [routerLink]="['/', lang, state, 'find-my-care']"
          >{{ translatedContent[lang].getStarted }}</a
        >
      </div>
    </div>
  </div>

  <div *ngIf="!state" class="ncoa-container-padding">
    <div class="flex flex-wrap justify-evenly">
      <div *ngIf="!blockContentLoaded" class="promo-area promo-area-state">
        <ngx-skeleton-loader
          [theme]="{
            height: '3.125rem'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            height: '11rem',
            width: '11rem'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div *ngIf="californiaIcon && blockContentLoaded" class="promo-area promo-area-state">
        <a (click)="resetQuestionnaire()" [routerLink]="['/', lang, 'ca']">
          <p [innerHtml]="californiaIcon.content.value" class="mb-6"></p>
          <img
            *ngIf="californiaIcon.icon.value.length"
            class="mx-auto w-44"
            src="{{ californiaIcon.icon.value[0].url }}"
            alt="{{ californiaIcon.title.value }}"
          />
        </a>
      </div>

      <div *ngIf="!blockContentLoaded" class="promo-area promo-area-state">
        <ngx-skeleton-loader
          [theme]="{
            height: '3.125rem'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            height: '11rem',
            width: '11rem'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div *ngIf="illinoisIcon && blockContentLoaded" class="promo-area promo-area-state">
        <a (click)="resetQuestionnaire()" [routerLink]="['/', lang, 'il']">
          <p [innerHtml]="illinoisIcon.content.value" class="mb-6"></p>
          <img
            *ngIf="illinoisIcon.icon.value.length"
            class="mx-auto w-44"
            src="{{ illinoisIcon.icon.value[0].url }}"
            alt="{{ illinoisIcon.title.value }}"
          />
        </a>
      </div>

      <div *ngIf="!blockContentLoaded" class="promo-area promo-area-state">
        <ngx-skeleton-loader
          [theme]="{
            height: '3.125rem'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            height: '11rem',
            width: '11rem'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div *ngIf="michiganIcon && blockContentLoaded" class="promo-area promo-area-state">
        <a (click)="resetQuestionnaire()" [routerLink]="['/', lang, 'mi']">
          <p [innerHtml]="michiganIcon.content.value" class="mb-6"></p>
          <img
            *ngIf="michiganIcon.icon.value.length"
            class="mx-auto w-44"
            src="{{ michiganIcon.icon.value[0].url }}"
            alt="{{ michiganIcon.title.value }}"
          />
        </a>
      </div>

      <div *ngIf="!blockContentLoaded" class="promo-area promo-area-state">
        <ngx-skeleton-loader
          [theme]="{
            height: '3.125rem'
          }"
        ></ngx-skeleton-loader>
        <ngx-skeleton-loader
          appearance="circle"
          [theme]="{
            height: '11rem',
            width: '11rem'
          }"
        ></ngx-skeleton-loader>
      </div>
      <div *ngIf="ohioIcon && blockContentLoaded" class="promo-area promo-area-state">
        <a (click)="resetQuestionnaire()" [routerLink]="['/', lang, 'oh']">
          <p [innerHtml]="ohioIcon.content.value" class="mb-6"></p>
          <img
            *ngIf="ohioIcon.icon.value.length"
            class="mx-auto w-44"
            src="{{ ohioIcon.icon.value[0].url }}"
            alt="{{ ohioIcon.title.value }}"
          />
        </a>
      </div>
    </div>
    <a
      *ngIf="lang"
      [routerLink]="['/', lang, 'get-help']"
      class="link link-with-arrow font-bold text-sm"
      >{{ translatedContent[lang].dontSeeYourState }}</a
    >
  </div>

  <div
    *ngIf="activatedRoute.children.length || state"
    class="flex flex-wrap ncoa-container mt-8 md:mt-12"
  >
    <div *ngIf="!stateContentLoaded" class="promo-area">
      <ngx-skeleton-loader
        appearance="circle"
        [theme]="{
          height: '11rem',
          width: '11rem'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          height: '3.125rem'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div *ngIf="promoArea1 && stateContentLoaded" class="promo-area">
      <a [routerLink]="['/', lang, state, 'find-my-care']">
        <img
          *ngIf="promoArea1.image.value.length"
          class="mx-auto"
          src="{{ promoArea1.image.value[0].url }}"
          alt="{{ promoArea1.title.value }}"
        />
        <p [innerHtml]="promoArea1.content.value"></p>
      </a>
    </div>

    <div *ngIf="!stateContentLoaded" class="promo-area">
      <ngx-skeleton-loader
        appearance="circle"
        [theme]="{
          height: '11rem',
          width: '11rem'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          height: '3.125rem'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div *ngIf="promoArea2 && stateContentLoaded" class="promo-area">
      <a [routerLink]="['/', lang, state, 'understand-my-care']">
        <img
          *ngIf="promoArea2.image.value.length"
          class="mx-auto"
          src="{{ promoArea2.image.value[0].url }}"
          alt="{{ promoArea2.title.value }}"
        />
        <p [innerHtml]="promoArea2.content.value"></p>
      </a>
    </div>

    <div *ngIf="!stateContentLoaded" class="promo-area">
      <ngx-skeleton-loader
        appearance="circle"
        [theme]="{
          height: '11rem',
          width: '11rem'
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        [theme]="{
          height: '3.125rem'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div *ngIf="promoArea3 && stateContentLoaded" class="promo-area">
      <a [routerLink]="['/', lang, state, 'care-choices']">
        <img
          *ngIf="promoArea3.image.value.length"
          class="mx-auto"
          src="{{ promoArea3.image.value[0].url }}"
          alt="{{ promoArea3.title.value }}"
        />
        <p [innerHtml]="promoArea3.content.value"></p>
      </a>
    </div>
  </div>
</section>

<router-outlet></router-outlet>
