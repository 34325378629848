<div
  class="
    side-nav
    text-base
    bg-gray-lightest
    overflow-hidden
    hidden
    h-full
    invisible
    flex-1
    max-w-max
    min-w-1/4
    md:block md:visible
  "
>
  <nav *ngIf="!understandMenuItems.length" aria-label="Side menu">
    <div class="w-72 p-6">
      <ngx-skeleton-loader
        count="7"
        [theme]="{
          height: '2rem',
          margin: '1.5rem 0 0 0',
          width: '100%'
        }"
      ></ngx-skeleton-loader>
    </div>
  </nav>
  <nav *ngIf="understandMenuItems.length" aria-label="Side menu">
    <ul class="p-0 font-bold">
      <li
        *ngFor="let item of understandMenuItems"
        class="bg-gray-lightest border-b border-white"
      >
        <a
          class="inline-block no-underline p-4 w-full"
          [routerLink]="item.componentLink"
          routerLinkActive="active"
          >{{ item.value }}</a
        >
      </li>
    </ul>
  </nav>
</div>
