<div class="ncoa-container-padding">
  <div
    *ngIf="title"
    class="text-3xl font-serif text-center md:text-4xl"
    [innerHtml]="title.content.value"
  ></div>
  <div
    *ngIf="!isCareModelsLoading && filteredCareModels.length && intro"
    class="text-center mt-6 max-w-3xl mx-auto"
    [innerHtml]="intro.content.value"
  ></div>
  <div
    *ngIf="!isCareModelsLoading && !filteredCareModels.length && noResultsIntro"
    class="text-center mt-6 max-w-3xl mx-auto"
    [innerHtml]="noResultsIntro.content.value"
  ></div>
  <div
    *ngIf="body"
    class="text-center text-sm mt-6 max-w-3xl mx-auto"
    [innerHtml]="body.content.value"
  ></div>
  <div
    *ngIf="isCareModelsLoading"
    class="h-72 mt-8 overflow-hidden rounded-3xl"
  >
    <ngx-skeleton-loader
      [theme]="{
        'border-radius': '1.5rem',
        height: '100%',
        width: '100%'
      }"
    ></ngx-skeleton-loader>
  </div>
  <section
    *ngIf="filteredCareModels.length && !isCareModelsLoading"
    class="mt-8 border border-green rounded-3xl overflow-hidden"
  >
    <!-- Desktop results -->
    <ul class="my-care-results hidden invisible lg:block lg:visible">
      <li
        class="flex items-center py-10 px-20"
        *ngFor="let careModel of filteredCareModels"
      >
        <div class="w-2/3">
          <h2 class="text-2xl font-serif">
            {{ careModel.title.value }}
          </h2>
          <p class="mt-6" [innerHtml]="careModel.introduction.value"></p>
        </div>
        <div class="w-1/3 flex justify-end">
          <a
            class="btn btn-green-dark inline-block"
            [routerLink]="['/', this.lang, this.state, careModel.url]"
            >{{ translatedContent[lang].viewChoices }}</a
          >
        </div>
      </li>
    </ul>
    <!-- Mobile results -->
    <ul class="my-care-results lg:hidden lg:invisible">
      <li *ngFor="let careModel of filteredCareModels">
        <a
          class="flex items-center p-4"
          [routerLink]="['/', this.lang, this.state, careModel.url]"
        >
          <div class="w-10/12">
            <h2 class="text-lg font-serif">
              {{ careModel.title.value }}
            </h2>
          </div>
          <span class="link-with-arrow -left-6 bottom-2.5 w-2/12"></span>
        </a>
      </li>
    </ul>
  </section>

  <section>
    <h2 class="text-3xl font-serif text-center mt-14 md:text-4xl">
      {{ translatedContent[lang].myAnswers }}
    </h2>
    <div
      *ngIf="myAnswersIntro"
      class="kentico-kontent text-center mt-6 max-w-3xl mx-auto"
      [innerHtml]="myAnswersIntro.content.value"
    ></div>
    <ul
      *ngIf="filteredQuestions && filteredQuestions.length"
      class="max-w-5xl mx-auto"
    >
      <li
        *ngFor="let question of filteredQuestions"
        class="mt-12 flex flex-col md:flex-row"
      >
        <div class="md:w-10/12">
          <p>
            <span class="font-bold">{{ question.untitled_text.value }}:</span>
            {{ question.answer.title }}
          </p>
          <div
            class="text-sm mt-2 mb-2 md:mb-0"
            [innerHtml]="question.answer.text"
          ></div>
        </div>
        <div class="md:w-2/12">
          <a
            [routerLink]="[
              '/',
              this.lang,
              this.state,
              'find-my-care',
              question.slug.value
            ]"
            class="link link-with-arrow font-bold text-sm"
            >{{ question.answer.text ? "Change" : "Answer" }}</a
          >
        </div>
      </li>
    </ul>
  </section>
</div>
