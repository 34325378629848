<!-- Desktop -->
<div
  class="
    w-10/12
    shadow-none
    mx-auto
    mat-elevation-z4
    hidden
    invisible
    flex-col
    md:flex md:visible
    lg:w-2/3
  "
>
  <div class="text-center bg-green-light rounded-xl py-10">
    <span [innerHtml]="bodyValue"></span>

    <form class="mt-8" [formGroup]="zipCodeForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="w-56 mr-2" appearance="fill">
        <mat-label>{{ translatedContent[lang].yourZipCode }}</mat-label>
        <input matInput formControlName="zipCode" value="" />
      </mat-form-field>

      <mat-form-field class="w-56 ml-2" appearance="fill">
        <mat-label>{{ translatedContent[lang].yourCounty }}</mat-label>
        <mat-select formControlName="county" disableRipple>
          <mat-option *ngFor="let county of counties" [value]="county.name">{{
            county.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="mt-4 btn btn-green-dark block mx-auto" type="submit">
        {{ translatedContent[lang].submit }}
      </button>
    </form>
  </div>

  <div
    *ngIf="resultsMessageBlock"
    class="mt-10"
    [innerHtml]="resultsMessageBlock.content.value"
  ></div>

  <ul *ngIf="plans" class="pl-0">
    <li class="plan mt-10 list-none" *ngFor="let plan of plans">
      <a
        class="info link-no-underline font-serif text-2xl"
        href="{{ plan.url }}"
        target="_blank"
        >{{ plan.name }}
        <span>Opens in a new tab</span>
      </a>
      <p *ngIf="!isAco && plan.id && plan.contract" class="mb-0 mt-4">
        ID: {{ plan.contract }}-{{ plan.id }}
      </p>
      <p *ngIf="!isAco && plan.organization" class="mb-0">
        {{ translatedContent[lang].organization }}: {{ plan.organization }}
      </p>
      <p *ngIf="isAco && plan.counties" class="mb-0 mt-4">
        {{ translatedContent[lang].countiesServed }}: {{ plan.counties }}
      </p>
      <p *ngIf="isAco && plan.email" class="mb-0">
        {{ translatedContent[lang].email }}: {{ plan.email }}
      </p>
      <p *ngIf="plan.phone" class="mb-0">
        {{ translatedContent[lang].phone }}: {{ plan.phone }}
      </p>
      <p *ngIf="!isAco && plan.tty" class="mb-0">TTY: {{ plan.tty }}</p>
    </li>
  </ul>
</div>

<!-- Mobile -->
<div class="shadow-none mx-auto mat-elevation-z4 md:hidden md:invisible">
  <div class="text-center bg-green-light rounded-xl p-8">
    <span [innerHtml]="bodyValue"></span>

    <form class="mt-8" [formGroup]="zipCodeForm" (ngSubmit)="onSubmit()">
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>{{ translatedContent[lang].yourZipCode }}</mat-label>
        <input matInput formControlName="zipCode" value="" />
      </mat-form-field>

      <mat-form-field class="w-full" appearance="fill">
        <mat-label>{{ translatedContent[lang].yourCounty }}</mat-label>
        <mat-select formControlName="county" disableRipple>
          <mat-option *ngFor="let county of counties" [value]="county.name">{{
            county.name
          }}</mat-option>
        </mat-select>
      </mat-form-field>

      <button class="mt-4 btn btn-green-dark block mx-auto" type="submit">
        {{ translatedContent[lang].submit }}
      </button>
    </form>
  </div>

  <div class="plan mt-10" *ngFor="let plan of plans">
    <a
      class="info link-no-underline font-serif text-lg"
      href="{{ plan.url }}"
      target="_blank"
      >{{ plan.name }}
      <span>Opens in a new tab</span>
    </a>
    <p *ngIf="!isAco && plan.id" class="mb-0 mt-4">ID: {{ plan.id }}</p>
    <p *ngIf="!isAco && plan.organization" class="mb-0">
      {{ translatedContent[lang].organization }}: {{ plan.organization }}
    </p>
    <p *ngIf="isAco && plan.counties" class="mb-0 mt-4">
      {{ translatedContent[lang].countiesServed }}: {{ plan.counties }}
    </p>
    <p *ngIf="isAco && plan.email" class="mb-0">
      {{ translatedContent[lang].email }}: {{ plan.email }}
    </p>
    <p *ngIf="plan.phone" class="mb-0">
      {{ translatedContent[lang].phone }}: {{ plan.phone }}
    </p>
    <p *ngIf="!isAco && plan.tty" class="mb-0">TTY: {{ plan.tty }}</p>
  </div>
</div>
