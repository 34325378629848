import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContentService } from '../services/content/content.service';
import { Glossary, Page } from '../models/content.model';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { Subscription } from 'rxjs';
import { find, findIndex, sortBy } from 'lodash';
import { glossaryLinks } from '../shared/data/glossary-links';

@Component({
  selector: 'app-glossary',
  templateUrl: './glossary.component.html',
  styleUrls: ['./glossary.component.scss'],
})
export class GlossaryComponent implements OnInit, OnDestroy {
  getPageSubscription!: Subscription;
  getGlossarySubscription!: Subscription;
  glossaryItems?: Glossary[];
  glossaryLinks = glossaryLinks;
  href!: string;
  lang!: string;
  page!: Page;
  state!: string;

  constructor(
    private contentService: ContentService,
    private routeHelperService: RouteHelperService
  ) {}

  ngOnInit(): void {
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.href = this.routeHelperService.getFinalPathValue();

    this.getPageContent();
    this.getGlossaryItems();
  }

  enableGlossaryLinks(): void {
    this.glossaryItems?.forEach((glossaryItem) => {
      const firstLetter = glossaryItem.term.value[0].toLowerCase();

      let foundItemIndex = findIndex(this.glossaryLinks, {
        value: firstLetter,
      });
      if (foundItemIndex !== -1) {
        this.glossaryLinks[foundItemIndex].enabled = true;
      }
    });
  }

  getGlossaryItems(): void {
    this.getGlossarySubscription = this.contentService
      .getGlossaryItems(this.lang, this.state)
      .subscribe(
        (res: any) => {
          try {
            this.glossaryItems = sortBy(res.items, ['term.value']);
            this.enableGlossaryLinks();
          } catch {
            console.error('There was a problem fetching page.');
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getPageContent(): void {
    const pageCodeName = this.routeHelperService.getCodeNameByState(
      this.lang,
      this.state,
      this.href
    );

    if (pageCodeName) {
      this.getPageSubscription = this.getPageSubscription = this.contentService
        .getPages(this.lang, this.state)
        .subscribe(
          (res: any) => {
            try {
              this.page = find(res.items, ['system.codename', pageCodeName]);
            } catch {
              console.error('There was a problem fetching page.');
            }
          },
          (e) => {
            console.error(e);
          }
        );
    }
  }

  isFirstItemOfLetter(glossaryItem: Glossary, index: number): boolean {
    if (index === 0) return true;

    const firstLetterOfPrevItem = this.glossaryItems![index - 1].term.value[0];
    const firstLetterOfThisItem = glossaryItem.term.value[0];

    return firstLetterOfThisItem === firstLetterOfPrevItem ? false : true;
  }

  ngOnDestroy(): void {
    if (this.getGlossarySubscription)
      this.getGlossarySubscription.unsubscribe();
    if (this.getPageSubscription) this.getPageSubscription.unsubscribe();
  }
}
