import { Injectable } from '@angular/core';
import { Age } from '../../models/questions.model';
import { Glossary } from '../../models/content.model';
import { sortBy, startsWith } from 'lodash';
import { MenuItem } from 'src/app/models/menu-item.model';
import { understandPagePathNames } from 'src/app/shared/data/routes-and-codenames';
import { HtmlProcessingService } from '../html-processing/html-processing.service';
import { RouteHelperService } from '../route-helper/route-helper.service';
import { pageRoutes } from 'src/app/shared/data/routes-and-codenames';
import { QuestionsService } from '../questions/questions.service';
import { Router } from '@angular/router';
import { Language } from '../../models/language.model';
import { ToolTipMap } from '../../models/tool-tip-map.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  questionnaireAges: Age[] = [
    {
      name: 'Under 21',
      value: 'under_21',
    },
    {
      name: '21 to 54',
      value: 'under_55',
    },
    {
      name: '55 to 64',
      value: 'under_65',
    },
    {
      name: '65+',
      value: 'over_65',
    },
  ];

  constructor(
    private htmlProcessingService: HtmlProcessingService,
    private questionsService: QuestionsService,
    private router: Router,
    private routeHelperService: RouteHelperService
  ) {}

  get ages() {
    return this.questionnaireAges;
  }

  createCareModelLink(codename: string, lang: string, state: string) {
    const componentLinkString =
      this.routeHelperService.getCareModelUrlByCodeName(codename, state);

    return ['/', lang, state, componentLinkString];
  }

  createPageLink(codename: string, lang: string, state: string) {
    const componentLinkString = this.routeHelperService.getPageUrlByCodeName(
      codename,
      state
    );

    return understandPagePathNames[state][codename]
      ? ['/', lang, state, pageRoutes.understandMyCare, componentLinkString]
      : ['/', lang, state, componentLinkString];
  }

  createMenuItem(contentItem: any, lang: string, state: string) {
    let componentLink = [''];
    let codename, intro, order, summary, value;

    try {
      codename = contentItem.system.codename;
      intro = contentItem.introduction.value;
      order = contentItem.menu__order.value;
      summary = contentItem.summary.value;
      value = contentItem.menu__label.value;

      componentLink =
        contentItem.system.type === 'page'
          ? this.createPageLink(codename, lang, state)
          : this.createCareModelLink(codename, lang, state);
    } catch {
      console.error(
        'There was a problem creating the menu item. One of the required values is likely missing.'
      );
    }

    let menuItem: MenuItem = {
      componentLink,
      intro,
      order,
      summary,
      value,
    };

    return menuItem;
  }

  createMenuItems(contentItems: any, lang: string, state: string) {
    let menuItems: MenuItem[] = [];

    contentItems.forEach((contentItem: any) => {
      let menuItem: MenuItem = this.createMenuItem(contentItem, lang, state);

      if (menuItem.order && menuItem.value) menuItems.push(menuItem);
    });

    let sortedMenuItems = sortBy(menuItems, ['order']);
    return sortedMenuItems;
  }

  /**
   * Builds an array of tab objects that we can loop through in the care model
   * component template. Each array item will be an object that contains the
   * properties for each tab.
   *
   * Ex. [ { body: "", subtitle: "", title: "" } ]
   */
  createTabs(careModel: any) {
    const tabPrefix = 'tab_';
    let careModelKeys = Object.keys(careModel);
    let numberOfTabs = 0;
    let tabs: any = [];
    const numberRegExp = /\d+/;

    // Find out how many tab objects we need by looking at the tab object values
    // Ex. tab_1_title, and save the one with the highest number.
    careModelKeys.forEach((careModelKey) => {
      if (careModelKey.startsWith(tabPrefix)) {
        let resultsArray = careModelKey.match(numberRegExp);

        if (resultsArray?.length) {
          const tabNumber = Number(resultsArray[0]);
          if (tabNumber > numberOfTabs) numberOfTabs = tabNumber;
        }
      }
    });

    // Create the array with the number of empty objects we need
    for (let i = 0; i < numberOfTabs; i++) {
      tabs.push({});
    }

    tabs.forEach((tab: any, i: number) => {
      const tabNumber = i + 1; // Necessary since the tab numbers start at 1

      tab['body'] = careModel[`${tabPrefix}${tabNumber}_body`];
      tab['subtitle'] = careModel[`${tabPrefix}${tabNumber}_subtitle`];
      tab['title'] = careModel[`${tabPrefix}${tabNumber}_title`];
    });

    return tabs;
  }

  createToolTipMap(glossaryItems: Glossary[]): ToolTipMap {
    let toolTipMap: {
      [index: string]: { definition: string; value: string };
    } = {};

    glossaryItems.forEach((glossaryItem: Glossary) => {
      const definition = this.htmlProcessingService.removeHtmlTags(
        glossaryItem.definition.value
      );
      const term = glossaryItem.term.value.toLowerCase();
      const value = this.htmlProcessingService.removeHtmlTags(
        glossaryItem.tooltip.value
      );

      toolTipMap[term] = {
        definition,
        value,
      };
    });
    return toolTipMap;
  }

  // Use the router to navigate to internal links.
  onAnchorClick = (event: Event): void => {
    const baseUrl = window.location.origin;
    const element = event.currentTarget as HTMLAnchorElement;
    const isInternalLink = startsWith(element.href, baseUrl);

    if (isInternalLink) {
      event.preventDefault();
      let href = element.getAttribute('href') || '';
      // Remove leading slash if it exists.
      if (startsWith(href, '/')) {
        let newHref = href.split('');
        newHref.shift();
        href = newHref.join('');
      }
      this.router.navigate([`/${href}`]);
    }
  };

  addEventListeners(): void {
    let anchors = document.getElementsByTagName('a');

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of anchors which doesn't have a forEach method.
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];
      if (anchor.classList.contains('js-kentico-link'))
        anchor.addEventListener('click', this.onAnchorClick);
    }
  }

  removeEventListeners(): void {
    let anchors = document.getElementsByTagName('a');

    // We have to use a for loop here because we need to loop over the
    // live html Nodelist of anchors which doesn't have a forEach method.
    for (let i = 0; i < anchors.length; i++) {
      let anchor = anchors[i];
      if (anchor.classList.contains('js-kentico-link'))
        anchor.removeEventListener('click', this.onAnchorClick);
    }
  }

  getLanguages(state: string) {
    let languages: {
      [index: string]: Language[];
    } = {
      ca: [
        { value: 'en', viewValue: 'English' },
        { value: 'es', viewValue: 'Español' },
        { value: 'zh-cn', viewValue: '简体中文' },
        { value: 'zh-tw', viewValue: '繁體中文' },
      ],
      oh: [
        { value: 'en', viewValue: 'English' },
        { value: 'es', viewValue: 'Español' },
      ],
    };
    let noState: Language[] = [
      { value: 'en', viewValue: 'English' },
      { value: 'es', viewValue: 'Español' },
    ];

    return languages[state] ? languages[state] : noState;
  }

  selectLang(lang: string, state: string): void {
    this.questionsService.resetAnswers();
    state
      ? this.router.navigate(['/', lang, state])
      : this.router.navigate(['/', lang]);
  }
}
