<div
  *ngIf="!careModel"
  class="ncoa-container-padding kentico-content px-4 md:px-8"
>
  <div class="w-full max-w-3xl mx-auto mt-6 md:mt-10">
    <ngx-skeleton-loader
      class="flex justify-center"
      [theme]="{
        height: '3.125rem',
        width: '50%'
      }"
    ></ngx-skeleton-loader>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
    <div class="mt-8">
      <ngx-skeleton-loader
        count="5"
        [theme]="{
          margin: '1rem 0 0 0'
        }"
      ></ngx-skeleton-loader>
    </div>
  </div>
</div>

<div
  *ngIf="careModel"
  class="ncoa-container-padding kentico-kontent px-4 md:px-8"
  role="main"
>
  <h1 class="text-2xl font-serif text-center max-w-3xl mx-auto lg:text-4xl">
    {{ careModel.title.value }}
  </h1>
  <div
    class="mt-6 max-w-3xl mx-auto"
    [innerHtml]="careModel.introduction.value"
  ></div>
  <div class="mt-6 max-w-3xl mx-auto" [innerHtml]="careModel.body.value"></div>

  <!-- Desktop tabbed content -->
  <div class="mt-10 mx-auto hidden invisible md:flex md:visible">
    <mat-tab-group
      class="w-full"
      animationDuration="0ms"
      dynamicHeight
      disableRipple
      [selectedIndex]="selectedIndex"
    >
      <ng-container *ngFor="let tab of processedTabs; let i = index">
        <mat-tab *ngIf="tab.title.value">
          <ng-template mat-tab-label>
            <h4>{{ tab.title.value }}</h4>
            <p class="hidden invisible lg:block lg:visible">
              {{ tab.subtitle.value }}
            </p>
          </ng-template>

          <!-- Show this component if the tab subtitle contains the word zip -->
          <app-care-choices-by-zip
            *ngIf="
              tab.subtitle.value.toLowerCase().includes('zip') ||
              tab.subtitle.value.toLowerCase().includes('código postal') ||
              tab.subtitle.value
                .toLowerCase()
                .includes('您所在郵遞區號地區中的可用選擇') ||
              tab.subtitle.value
                .toLowerCase()
                .includes('您所在邮政编码地区的可用选择')
            "
            [bodyValue]="tab.body.value"
            [isAco]="isAco"
            [codename]="codename"
          ></app-care-choices-by-zip>

          <!-- Show this div if the tab subtitle does not contain the word zip -->
          <div
            *ngIf="
              !tab.subtitle.value.toLowerCase().includes('zip') &&
              !tab.subtitle.value.toLowerCase().includes('código postal') &&
              !tab.subtitle.value
                .toLowerCase()
                .includes('您所在郵遞區號地區中的可用選擇') &&
              !tab.subtitle.value
                .toLowerCase()
                .includes('您所在邮政编码地区的可用选择')
            "
            class="w-10/12 shadow-none mx-auto mat-elevation-z4 lg:w-2/3"
          >
            <span [innerHtml]="tab.body.value"></span>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>

  <!-- Mobile tabbed content -->
  <div
    class="
      mt-10
      border border-green
      rounded-xl
      overflow-auto
      md:hidden md:invisible
    "
  >
    <ng-container *ngFor="let tab of processedTabs; let i = index">
      <mat-expansion-panel
        *ngIf="tab.title.value"
        class="rounded-none shadow-none"
        [expanded]="i === selectedIndex"
      >
        <mat-expansion-panel-header class="py-8">
          <mat-panel-title>
            <h4 class="text-xl my-0">{{ tab.title.value }}</h4>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Show this component if the tab subtitle contains the word zip -->
        <app-care-choices-by-zip
          *ngIf="
            tab.subtitle.value.toLowerCase().includes('zip') ||
            tab.subtitle.value.toLowerCase().includes('código postal') ||
            tab.subtitle.value
              .toLowerCase()
              .includes('您所在郵遞區號地區中的可用選擇') ||
            tab.subtitle.value
              .toLowerCase()
              .includes('您所在邮政编码地区的可用选择')
          "
          [bodyValue]="tab.body.value"
          [isAco]="isAco"
          [codename]="codename"
        ></app-care-choices-by-zip>

        <!-- Show this div if the tab subtitle does not contain the word zip -->
        <div
          *ngIf="
            !tab.subtitle.value.toLowerCase().includes('zip') &&
            !tab.subtitle.value.toLowerCase().includes('código postal') &&
            !tab.subtitle.value
              .toLowerCase()
              .includes('您所在郵遞區號地區中的可用選擇') &&
            !tab.subtitle.value
              .toLowerCase()
              .includes('您所在邮政编码地区的可用选择')
          "
          class="shadow-none mx-auto mat-elevation-z4"
        >
          <span [innerHtml]="tab.body.value"></span>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </div>
</div>
