import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { UtilityService } from '../../../services/utility/utility.service';
import { ContentService } from '../../../services/content/content.service';
import { CareModel, Page } from '../../../models/content.model';
import { NavigationEnd, Router } from '@angular/router';
import { RouteHelperService } from '../../../services/route-helper/route-helper.service';
import { MenuItem } from '../../../models/menu-item.model';
import { find } from 'lodash';
import { Subscription } from 'rxjs';
import { languageRoutes, pageRoutes } from '../../data/routes-and-codenames';
import { translatedContent } from '../../data/translated-content';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { Language } from '../../../models/language.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
  @Input() toggleSearch!: Function;
  @ViewChild('careChoicesPanel') careChoicesPanel!: MatExpansionPanel;
  @ViewChild('mainPanel') mainPanel!: MatExpansionPanel;
  @ViewChild('understandPanel') understandPanel!: MatExpansionPanel;
  @ViewChild('menuToggle') menuToggle!: MatButtonToggle;

  careChoicesLabel!: string;
  careChoiceMenuItems: MenuItem[] = [];
  careChoiceMenuItemsLoaded = false;
  careModels!: CareModel[];
  getCareModelsSubscription!: Subscription;
  getPagesSubscription!: Subscription;
  getStartedLabel!: string;
  lang!: string;
  languageRoutes: any;
  linkLabelsLoaded = false;
  pageRoutes: any;
  pages!: Page[];
  state!: string;
  understandMyCareLabel!: string;
  languages!: Language[];
  translatedContent: any;
  understandMenuItems: MenuItem[] = [];
  understandMenuItemsLoaded = false;

  constructor(
    private contentService: ContentService,
    private router: Router,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { lang, state } = this.routeHelperService.getRouteValues();
        this.lang = lang;
        this.state = state;
        this.getLanguages();

        if (this.state) {
          this.getCareModels();
          this.getPages();
        }
      }
    });
  }

  ngOnInit(): void {
    this.languageRoutes = languageRoutes;
    this.pageRoutes = pageRoutes;
    this.translatedContent = translatedContent;

    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.getLanguages();

    if (this.state) {
      this.getCareModels();
      this.getPages();
    }
  }

  onKeyDown(event: any) {
    if (event.code === 'Space') {
      event.preventDefault();
      const menuTrigger =
        event.target.parentElement.querySelector('.js-menu-trigger');

      if (!menuTrigger) return;

      if (menuTrigger.classList.contains('active-dropdown')) {
        this.hideDropdown(event);
      } else {
        this.showDropdown(event);
      }
    }
  }

  hideDropdown(event: any): void {
    let menuTrigger = event.target.querySelector('.js-menu-trigger');

    if (!menuTrigger)
      menuTrigger =
        event.target.parentElement.querySelector('.js-menu-trigger');

    menuTrigger?.classList.remove('active-dropdown');
  }

  showDropdown(event: any): void {
    // First remove active-dropdown class from all js-menu-trigger elements.
    let menuTriggers = document.querySelectorAll('.active-dropdown');
    menuTriggers.forEach((menuTrigger) => {
      if (!menuTrigger === event.target.querySelector('.active-dropdown'))
        menuTrigger.classList.remove('active-dropdown');
    });

    let menuTrigger = event.target.querySelector('.js-menu-trigger');

    if (!menuTrigger)
      menuTrigger =
        event.target.parentElement.querySelector('.js-menu-trigger');

    menuTrigger?.classList.add('active-dropdown');
  }

  selectLang(lang: string): void {
    this.utilityService.selectLang(lang, this.state);
  }

  getCareModels(): void {
    this.getCareModelsSubscription = this.contentService
      .getCareModels(this.lang, this.state)
      .subscribe(
        (res: any) => {
          if (res.items) {
            this.careModels = res.items;
            this.careChoiceMenuItems = this.utilityService.createMenuItems(
              this.careModels,
              this.lang,
              this.state
            );
            this.careChoiceMenuItemsLoaded = true;
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getLanguages(): void {
    this.languages = this.utilityService.getLanguages(this.state);
  }

  getPages(): void {
    this.getPagesSubscription = this.contentService
      .getPages(this.lang, this.state)
      .subscribe(
        (res: any) => {
          if (res.items) {
            this.pages = res.items;
            this.getLinkLabels();
            this.understandMenuItems = this.utilityService.createMenuItems(
              this.pages,
              this.lang,
              this.state
            );
            this.understandMenuItemsLoaded = true;
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  getLinkLabels(): void {
    const understandCodename = this.routeHelperService.getCodeNameByUrl(
      this.lang,
      this.state,
      pageRoutes.understandMyCare
    );
    const careChoicesCodename = this.routeHelperService.getCodeNameByUrl(
      this.lang,
      this.state,
      pageRoutes.careChoices
    );
    const getStartedCodename = this.routeHelperService.getCodeNameByUrl(
      this.lang,
      this.state,
      pageRoutes.findMyCare
    );
    let understandMyCarePage = find(this.pages, [
      'system.codename',
      understandCodename,
    ]);
    let careChoicesPage = find(this.pages, [
      'system.codename',
      careChoicesCodename,
    ]);
    let getStartedPage = find(this.pages, [
      'system.codename',
      getStartedCodename,
    ]);
    if (understandMyCarePage)
      this.understandMyCareLabel = understandMyCarePage.menu__label.value;
    if (careChoicesPage)
      this.careChoicesLabel = careChoicesPage.menu__label.value;
    if (getStartedPage) this.getStartedLabel = getStartedPage.menu__label.value;

    this.linkLabelsLoaded = true;
  }

  onMobileDropdownClick(): void {
    this.mainPanel.toggle();
    if (this.careChoicesPanel && this.careChoicesPanel.expanded)
      this.careChoicesPanel.close();
    if (this.understandPanel && this.understandPanel.expanded)
      this.understandPanel.close();
  }

  onMobileMenuItemClick(): void {
    this.menuToggle.checked = false;
    this.careChoicesPanel && this.careChoicesPanel.close();
    this.mainPanel.close();
    this.understandPanel && this.understandPanel.close();
  }

  ngOnDestroy(): void {
    if (this.getCareModelsSubscription)
      this.getCareModelsSubscription.unsubscribe();
    if (this.getPagesSubscription) this.getPagesSubscription.unsubscribe();
  }
}
