import { AfterViewChecked, Component, OnDestroy, OnInit } from '@angular/core';
import { QuestionsService } from '../services/questions/questions.service';
import { ContentService } from '../services/content/content.service';
import { Block, CareModel } from '../models/content.model';
import { RouteHelperService } from '../services/route-helper/route-helper.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { pageRoutes } from '../shared/data/routes-and-codenames';
import { translatedContent } from '../shared/data/translated-content';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { GetStartedDialogComponent } from './get-started-dialog/get-started-dialog.component';
import { UtilityService } from '../services/utility/utility.service';
import { HtmlProcessingService } from '../services/html-processing/html-processing.service';
import { find, sortBy } from 'lodash';

@Component({
  selector: 'app-my-care-results',
  templateUrl: './my-care-results.component.html',
  styleUrls: ['./my-care-results.component.scss'],
})
export class MyCareResultsComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  body?: Block;
  careModels!: CareModel[];
  filteredCareModels: any[] = [];
  filteredQuestions: any[] = [];
  getBlocksSubscription!: Subscription;
  getCareModelsSubscription!: Subscription;
  intro?: Block;
  isAnswersValid!: boolean;
  isCareModelsLoading = true;
  lang!: string;
  myAnswersIntro?: Block;
  noResultsIntro?: Block;
  noResultsOverlay?: Block;
  oneResultOverlay?: Block;
  questions!: any[];
  resultsSubscription!: Subscription;
  state!: string;
  title?: Block;
  tooManyMessage?: Block;
  translatedContent: any;

  constructor(
    private contentService: ContentService,
    private htmlProcessingService: HtmlProcessingService,
    private questionsService: QuestionsService,
    private router: Router,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;

    this.isAnswersValid = this.questionsService.isAnswersValid;
    if (!this.isAnswersValid) {
      this.router.navigate(['/', this.lang, this.state, pageRoutes.findMyCare]);
    } else {
      this.questions = this.questionsService.questionsWithAnswers;
      this.filterSubQuestions();
      this.questionsService.setHasSeenResults();
      this.getBlocks();
      this.getCareModels();
    }
  }

  getBlocks(): void {
    this.getBlocksSubscription = this.contentService
      .getBlocks(this.lang)
      .subscribe((data: { items: Block[] }) => {
        try {
          this.body = data.items?.find(
            (element) =>
              element.system.codename === `plans_results_body___${this.state}`
          );
          this.intro = data.items?.find(
            (element) =>
              element.system.codename ===
              `plans_results_introduction___${this.state}`
          );
          this.myAnswersIntro = data.items?.find(
            (element) =>
              element.system.codename ===
              `plans_my_answers_introduction___${this.state}`
          );
          this.noResultsIntro = data.items?.find(
            (element) =>
              element.system.codename ===
              `plans_no_results_introduction___${this.state}`
          );
          this.noResultsOverlay = data.items?.find(
            (element) =>
              element.system.codename ===
              `get_started_no_result_overlay___${this.state}`
          );
          this.oneResultOverlay = data.items?.find(
            (element) =>
              element.system.codename ===
              `get_started_one_result_overlay___${this.state}`
          );
          this.title = data.items?.find(
            (element) =>
              element.system.codename === `plans_results_title___${this.state}`
          );
          this.tooManyMessage = data.items?.find(
            (element) =>
              element.system.codename ===
              `plans_too_many_message___${this.state}`
          );
        } catch {
          console.error('There was a problem fetching blocks.');
        }
      });
  }

  getCareModels(): void {
    this.isCareModelsLoading = true;
    this.getCareModelsSubscription = this.contentService
      .getCareModels(this.lang, this.state)
      .subscribe((data: any) => {
        try {
          this.careModels = data.items;
          this.filterCareModels();
        } catch {
          console.error('There was a problem fetching care models.');
        }
      });
  }

  filterCareModels(): void {
    const filteredCareModels = this.contentService.filteredCareModels;

    if (filteredCareModels.length) {
      this.filteredCareModels = filteredCareModels;
      this.checkDialog();
      this.isCareModelsLoading = false;
      return;
    }

    this.resultsSubscription = this.questionsService.results
      .pipe(
        finalize(() => {
          this.isCareModelsLoading = false;
        })
      )
      .subscribe(
        (res: any) => {
          try {
            const returnedCareModels = sortBy(res.data, 'attributes.score').reverse();

            // Add elements that only contain the care models returned from
            // the questionnaire results API call to filteredCareModels.
            returnedCareModels.forEach((returnedCareModel: any) => {
              if (returnedCareModel.id) {
                const codename = returnedCareModel.id;

                this.careModels.forEach((componentCareModel) => {
                  if (
                    componentCareModel.system &&
                    componentCareModel.system.codename === codename
                  ) {
                    // Set the url of the care model so we can link to it from
                    // the results page.
                    componentCareModel.url = this.routeHelperService.getCareModelUrlByCodeName(
                      codename,
                      this.state
                    );
                    this.filteredCareModels.push(componentCareModel);
                    return;
                  }
                });
              }
            });

            this.contentService.setFilteredCareModels(this.filteredCareModels);
            this.checkDialog();
          } catch {
            console.error(
              'There was a problem fetching questionnaire results.'
            );
          }
        },
        (e) => {
          console.error(e);
        }
      );
  }

  /**
   * Remove sub-questions (questions with non whole-number ID's) that have a
   * parent question that was not answered yes or maybe.
   */
  filterSubQuestions(): void {
    this.questions.forEach((question) => {
      const questionId = question.reference_id.value;
      const isSubQuestion = !!(Number(questionId) % 1);

      if (!isSubQuestion) {
        this.filteredQuestions.push(question);
        return;
      }

      const baseQuestionInt = Math.trunc(Number(questionId));
      let baseQuestion = find(this.questions, {
        reference_id: { value: `${baseQuestionInt}.0` },
      });

      if (
        baseQuestion.answer.title.toLowerCase() === 'yes' ||
        baseQuestion.answer.title.toLowerCase() === 'maybe'
      )
        this.filteredQuestions.push(question);
    });
  }

  checkDialog(): void {
    if (!this.filteredCareModels.length) {
      let data = { intro: this.noResultsOverlay?.content.value };

      this.dialog.open(GetStartedDialogComponent, { data });
    }
  }

  ngAfterViewChecked(): void {
    this.htmlProcessingService.processKenticoAnchorsHtml();
    this.utilityService.addEventListeners();
  }

  ngOnDestroy(): void {
    if (this.getBlocksSubscription) this.getBlocksSubscription.unsubscribe();
    if (this.getCareModelsSubscription)
      this.getCareModelsSubscription.unsubscribe();
    if (this.resultsSubscription) this.resultsSubscription.unsubscribe();
    this.utilityService.removeEventListeners();
  }
}
