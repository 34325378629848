import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteHelperService } from '../../../services/route-helper/route-helper.service';
import { QuestionsService } from 'src/app/services/questions/questions.service';
import { languageRoutes, pageRoutes } from '../../data/routes-and-codenames';
import { translatedContent } from '../../data/translated-content';
import { UtilityService } from 'src/app/services/utility/utility.service';
import { Language } from '../../../models/language.model';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  href!: string;
  isUnderstandPage!: boolean;
  isUnderstandSubpage!: boolean;
  lang?: string;
  languageRoutes: any;
  languages!: Language[];
  pageRoutes: any;
  state!: string;
  translatedContent: any;

  constructor(
    private questionsService: QuestionsService,
    private router: Router,
    private routeHelperService: RouteHelperService,
    private utilityService: UtilityService
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { lang, state } = this.routeHelperService.getRouteValues();
        this.lang = lang;
        this.state = state;
        this.getLanguages();
        this.href = this.routeHelperService.getFinalPathValue();
        this.isUnderstandSubpage = this.routeHelperService.isUnderstandSubpage(
          this.router.url
        );
        this.isUnderstandPage =
          this.href === pageRoutes.understandMyCare || this.isUnderstandSubpage;
      }
    });
  }

  ngOnInit(): void {
    this.languageRoutes = languageRoutes;
    this.pageRoutes = pageRoutes;
    this.translatedContent = translatedContent;

    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.getLanguages();
    this.href = this.routeHelperService.getFinalPathValue();
    this.isUnderstandSubpage = this.routeHelperService.isUnderstandSubpage(
      this.router.url
    );
    this.isUnderstandPage =
      this.href === pageRoutes.understandMyCare || this.isUnderstandSubpage;
  }

  getLanguages(): void {
    this.languages = this.utilityService.getLanguages(this.state);
  }

  selectLang(lang: string): void {
    this.utilityService.selectLang(lang, this.state);
  }

  selectState(state: string) {
    this.resetQuestionnaire();
    this.router.navigate(['/', this.lang, state]);
  }

  resetQuestionnaire() {
    this.questionsService.resetAnswers();
  }
}
