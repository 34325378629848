import { Component, OnInit, DoCheck } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { Question } from '../../../models/content.model';
import { QuestionsService } from '../../../services/questions/questions.service';
import { ContentService } from '../../../services/content/content.service';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from './question-dialog/question-dialog.component';
import { RouteHelperService } from 'src/app/services/route-helper/route-helper.service';
import { pageRoutes } from '../../data/routes-and-codenames';
import { translatedContent } from '../../data/translated-content';

@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent implements OnInit, DoCheck {
  hasSeenResults!: boolean;
  href!: string;
  isLastQuestion!: boolean;
  lang!: string;
  progressValue!: number;
  question!: Question;
  questionForm!: FormGroup;
  questionNumber!: string;
  state!: string;
  baseRoute!: string[];
  parentRoute!: string[];
  translatedContent: any;

  constructor(
    private contentService: ContentService,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private questionsService: QuestionsService,
    private routeHelperService: RouteHelperService,
    private router: Router
  ) {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        const { lang, state } = this.routeHelperService.getRouteValues();
        this.lang = lang;
        this.state = state;
      }
    });
  }

  ngOnInit(): void {
    this.translatedContent = translatedContent;
    const { lang, state } = this.routeHelperService.getRouteValues();
    this.lang = lang;
    this.state = state;
    this.baseRoute = ['/', this.lang, this.state];
    this.parentRoute = [...this.baseRoute, pageRoutes.findMyCare];
    this.href = this.routeHelperService.getFinalPathValue();
    this.getQuestion();
    this.hasSeenResults = this.questionsService.hasSeenResults;

    if (!this.question.reference_id) {
      this.router.navigate(this.parentRoute);
    }

    this.setupForm();
  }

  ngDoCheck() {
    this.href = this.routeHelperService.getFinalPathValue();
    this.getQuestion();
    this.setupForm();
  }

  openDialog(): void {
    this.dialog.open(QuestionDialogComponent, {
      data: {
        body: this.question?.prompt_text.value,
        lang: this.lang,
        translatedContent: this.translatedContent,
      },
    });
  }

  setupForm() {
    this.questionForm = this.fb.group({
      question: [
        this.questionsService.getAnswer(this.questionNumber)?.answer,
        Validators.required,
      ],
    });

    this.questionForm.get('question')?.valueChanges.subscribe((answer) => {
      this.questionsService.setAnswer(this.questionNumber, answer);
      this.contentService.resetFilteredCareModels();
    });
  }

  getQuestion() {
    this.question = this.questionsService.getQuestionBySlug(this.href).question;
    this.isLastQuestion = this.questionsService.isLastQuestion();

    if (this.question.reference_id) {
      this.questionNumber = this.question.reference_id.value;
      this.progressValue = Number(this.questionNumber) + 1; // add 1 to account for the find-my-care component
    }
  }

  onNextClick() {
    if (this.isLastQuestion) {
      this.router.navigate([...this.baseRoute, pageRoutes.results]);
    } else {
      this.router.navigate([
        ...this.parentRoute,
        this.questionsService.getNextQuestionSlug(),
      ]);
    }
  }

  onBackClick() {
    if (this.questionsService.isFirstQuestion()) {
      this.router.navigate(this.parentRoute);
    } else {
      this.router.navigate([
        ...this.parentRoute,
        this.questionsService.getPrevQuestionSlug(),
      ]);
    }
  }
}
