import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';

import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';

import { FooterComponent } from './shared/components/footer/footer.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';

import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { FindMyCareComponent } from './find-my-care/find-my-care.component';
import { UnderstandMyCareComponent } from './understand-my-care/understand-my-care.component';
import { GlossaryComponent } from './glossary/glossary.component';
import { QuestionComponent } from './shared/components/question/question.component';
import { QuestionDialogComponent } from './shared/components/question/question-dialog/question-dialog.component';
import { MyCareResultsComponent } from './my-care-results/my-care-results.component';
import { CareModelComponent } from './care-model/care-model.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar.component';
import { CareChoicesComponent } from './care-choices/care-choices.component';
import { CareChoicesByZipComponent } from './care-model/care-choices-by-zip/care-choices-by-zip.component';
import { ParentComponent } from './parent/parent.component';
import { BasicPageComponent } from './basic-page/basic-page.component';
import { SideNavComponent } from './shared/components/side-nav/side-nav.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { GetStartedDialogComponent } from './my-care-results/get-started-dialog/get-started-dialog.component';
import { DialogComponent } from './shared/components/dialog/dialog.component';
import { ScullyLibModule } from '@scullyio/ng-lib';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    FindMyCareComponent,
    QuestionComponent,
    UnderstandMyCareComponent,
    GlossaryComponent,
    QuestionDialogComponent,
    MyCareResultsComponent,
    CareModelComponent,
    ProgressBarComponent,
    CareChoicesComponent,
    CareChoicesByZipComponent,
    ParentComponent,
    BasicPageComponent,
    SideNavComponent,
    SearchFormComponent,
    SearchResultsComponent,
    GetStartedDialogComponent,
    DialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatButtonToggleModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatTabsModule,
    FormsModule,
    MatInputModule,
    ScullyLibModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
