<div
  class="
    fixed
    w-full
    h-full
    bg-gray-lighter
    opacity-95
    z-10
    text-center
    md:text-left
  "
>
  <div class="ncoa-container-padding flex h-full items-center">
    <div *ngIf="careModels || pages" class="w-full md:flex">
      <form
        [formGroup]="searchForm"
        (ngSubmit)="onSubmit()"
        class="flex-1 items-baseline md:flex"
      >
        <label for="search-term" class="font-bold font-serif text-xl w-56"
          >{{ translatedContent[lang].searchFor }}:</label
        >
        <mat-form-field class="font-bold w-full">
          <input
            matInput
            formControlName="searchTerm"
            id="search-term"
            value=""
          />
          <mat-error *ngIf="searchForm.controls.searchTerm.invalid">{{
            translatedContent[lang].searchTermRequired
          }}</mat-error>
        </mat-form-field>
        <button class="btn btn-green-dark block mx-auto md:mx-6" type="submit">
          {{ translatedContent[lang].submit }}
        </button>
      </form>
      <div>
        <button
          class="
            button-close
            search-close
            font-bold
            text-green-dark text-xs
            pt-10
          "
          (click)="toggleSearch()"
        >
          {{ translatedContent[lang].close }}
        </button>
      </div>
    </div>
  </div>
</div>
